import { createContext, useContext, useEffect } from 'react';
import { useState } from 'react';
import { deleteCookie, getCookie } from '../services/cookieController';
import { readJwt } from '../services/tokenValidation';
import { SBT_SITE_URL, SBT_TV_URL, SBT_VIDEOS_URL } from '../data/Constants';
import {
  getOriginDomain,
  isValidCustomOrigin,
  iOS,
  isWebview
} from '../utils/GenericFunctions';
import {
  ORIGIN_DOMAIN_SBT_GAMES,
  ORIGIN_DOMAIN_SBT_TV
} from '../data/Constants';
import { IUser } from '../interfaces/user';
export const KEY_SESSION = process.env.REACT_APP_KEY_SESSION as string;
export const KEY_REFRESH_SESSION = process.env
  .REACT_APP_KEY_REFRESH_SESSION as string;
const KEY_SESSION_SOCIAL = process.env.REACT_APP_KEY_SESSION_SOCIAL as string;
const KEY_SESSION_PROVIDER = process.env
  .REACT_APP_KEY_SESSION_PROVIDER as string;
// const ORGANIZACAO = process.env.REACT_APP_ORGANIZACAO_ETRUST;
const KEY_SESSION_ORIGIN = process.env.REACT_APP_KEY_SESSION_ORIGIN as string;
export interface AuthContextData {
  user: IUser;
  setUser: React.Dispatch<React.SetStateAction<IUser>>;
  getOrigin: () => string;
  accountDisabled: boolean;
  setAccountDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  openSession: boolean;
  setOpenSession: React.Dispatch<React.SetStateAction<boolean>>;
  checkAuth: (urlToken?: string) => any;
  clearSession: () => void;
  getTokenSession(): any;
  createRedirectURL(baseURL: string): string;
  getSocialSession(): any;
  getSocialProvider(): any;
  redirectToSite(): void;
  allSessions: any;
  setAllSessions: React.Dispatch<any>;
  setSSORedirect(url: string): void;
  redirectURL: string;
  openGoBackToApp: boolean;
  setOpenGoBackToApp: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AuthContext = createContext({} as AuthContextData);

const AuthProvider = ({ children }: any) => {
  const [openSession, setOpenSession] = useState(false);
  const [accountDisabled, setAccountDisabled] = useState(false);
  const [user, setUser] = useState({} as IUser);
  const [allSessions, setAllSessions] = useState<any>([]);
  const [redirectURL, setRedirectURL] = useState<string>('');
  const [openGoBackToApp, setOpenGoBackToApp] = useState<boolean>(false);

  useEffect(() => {
    listAllSessions();
  }, []);

  async function listAllSessions() {
    const check = checkAuth();
    const token = getTokenSession();
    if (check?.user && token) {
      try {
        // const response = await getListAllSessions(check.user.email);
        // setAllSessions(response);
      } catch (error) {
        console.error(error);
      }
    }
  }

  function setSSORedirect(url: string) {
    setRedirectURL(url);
  }

  function getSocialSession() {
    return getCookie(KEY_SESSION_SOCIAL);
  }

  function getSocialProvider() {
    return getCookie(KEY_SESSION_PROVIDER);
  }

  function getTokenSession() {
    const token: any = getCookie(KEY_SESSION);
    return token;
  }

  function getRefreshTokenSession() {
    const refreshToken: any = getCookie(KEY_REFRESH_SESSION);
    return refreshToken;
  }

  function checkAuth(urlToken?: string) {
    const decodeToken: any = readJwt(urlToken || getTokenSession());
    return decodeToken;
  }

  function clearSession() {
    localStorage.clear();
    deleteCookie(KEY_SESSION);
    deleteCookie(KEY_REFRESH_SESSION);
    deleteCookie(KEY_SESSION_SOCIAL);
    deleteCookie(KEY_SESSION_PROVIDER);
    deleteCookie(KEY_SESSION_ORIGIN);
  }

  function getOrigin() {
    let origin = '';
    const regexOrigin = /videos/g;
    const regexAppOrigin = /sbt/g;
    if (regexOrigin.test(window.location.pathname)) {
      origin = 'videos';
    } else if (regexAppOrigin.test(window.location.pathname)) {
      origin = 'sbt';
    } else {
      origin = '';
    }
    return origin;
  }

  function onSuccessLogin(token: string, refresh_token: string) {
    const stringParam = JSON.stringify({
      event: 'onSuccessLogin',
      token,
      refresh_token
    });

    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.ReactNativeWebView.postMessage(stringParam);
    } catch (error) {
      console.log(error);
    }

    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.webkit.messageHandlers.onSuccessLogin.postMessage(stringParam);
    } catch (error) {
      console.log(error);
    }

    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const YoutubePlayerView = window.YoutubePlayerView;
      YoutubePlayerView.onSuccessLogin(stringParam);
    } catch (error) {
      console.log(error);
    }
  }

  function createRedirectURL(baseURL: string): string {
    const token = getTokenSession();
    const refreshToken = getRefreshTokenSession();
    return `${
      redirectURL || getCookie('redirect') || baseURL
    }?refer=&jwt=${token}&refresh_token=${refreshToken}&return_to=${redirectURL}`;
  }

  function redirectToSite() {
    try {
      setTimeout(() => {
        const token = getTokenSession();
        const refreshToken = getCookie(KEY_REFRESH_SESSION) || '';

        const origin = getOriginDomain();
        if (
          getOrigin() === 'sbt' &&
          isValidCustomOrigin(origin) &&
          isWebview()
        ) {
          if (origin === ORIGIN_DOMAIN_SBT_GAMES) {
            const appRedirect = getCookie('app_redirect');

            setTimeout(() => setOpenGoBackToApp(true), 1000);
            window.open(
              `${appRedirect}?token=${token}&refresh_token=${refreshToken}`,
              '_self'
            );
          } else {
            const isIOS = iOS();
            if (isIOS) {
              window.open(
                `sbtnews://?token=${token}&refresh_token=${refreshToken}`,
                '_self'
              );
            } else {
              setTimeout(() => setOpenGoBackToApp(true), 1000);
              window.open(
                `sbtnews://sbtnews?token=${token}&refresh_token=${refreshToken}`,
                '_self'
              );
              window.open(
                `intent://sbtnews?token=${token}&refresh_token=${refreshToken}#Intent;scheme=sbtnews;action=android.intent.action.VIEW;end`,
                '_self'
              );
            }
          }
          return onSuccessLogin(token, refreshToken);
        }

        const decoded = readJwt(token);
        if (decoded && typeof decoded != 'string' && decoded.exp) {
          const currentTime = Math.round(Date.now() / 1000);
          console.log('Atual:', currentTime, 'TOKEN:', decoded.exp);
        }

        const baseURL =
          getOrigin() == 'videos'
            ? SBT_VIDEOS_URL
            : getOrigin() === 'sbt' && origin === ORIGIN_DOMAIN_SBT_TV
              ? SBT_TV_URL
              : SBT_SITE_URL;

        window.open(createRedirectURL(baseURL), '_self');
      }, 800);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        getOrigin,
        openSession,
        setOpenSession,
        checkAuth,
        accountDisabled,
        setAccountDisabled,
        clearSession,
        getTokenSession,
        createRedirectURL,
        getSocialSession,
        getSocialProvider,
        redirectToSite,
        allSessions,
        setAllSessions,
        setSSORedirect,
        redirectURL,
        openGoBackToApp,
        setOpenGoBackToApp
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);

export default AuthProvider;
