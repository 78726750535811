import { useNavigate } from 'react-router-dom';
import CardIcon from '../../components/Card/CardIcon';
import Icon from '../../components/Icon/Icon';
import Modal, { ModalHandles } from '../../components/Modal/Modal';
import ModalTitle from '../../components/Modal/ModalTitle';
import ModalDescription from '../../components/Modal/ModalDescription';
import { useRef } from 'react';
import Button from '../../components/Button/Button';
import ButtonLabel from '../../components/Button/ButtonLabel';

export default function OptionsAccount() {
  const navigate = useNavigate();

  const modalRef = useRef<ModalHandles>(null);
  function openModal() {
    modalRef.current?.openModal();
  }

  function closeModal() {
    modalRef.current?.closeModal();
  }

  function redirectToPrivacyPolicy() {
    window.open('https://www.sbt.com.br/politica-de-privacidade');
  }

  return (
    <>
      <CardIcon
        onClick={() => navigate('/menu-de-conta/atualizar-dados')}
        title="dados da conta"
        description="Consulte e altere seus dados e sua senha da conta SBT"
      >
        <Icon name="accountData" />
      </CardIcon>
      <CardIcon
        onClick={() => navigate('/menu-de-conta/gerenciar-sessoes-ativas')}
        title="histórico de sessões ativas"
        description="Consulte e desconecte as sessões ativas da sua Conta SBT"
      >
        <Icon name="device" />
      </CardIcon>
      <CardIcon
        onClick={() => navigate('/menu-de-conta/preferencias-de-comunicacao')}
        title="preferências de comunicação"
        description="Gerencie as comunicações que você recebe do SBT"
      >
        <Icon name="setup" />
      </CardIcon>
      <CardIcon
        onClick={openModal}
        title="baixar dados"
        description="Baixe todos os seus dados vinculados à sua Conta SBT"
      >
        <Icon name="download" />
      </CardIcon>
      <CardIcon
        onClick={() => navigate('excluir-conta')}
        title="excluir conta"
        description="Exclua permanentemente sua Conta SBT e todos os seus dados"
      >
        <Icon name="delete" />
      </CardIcon>

      <Modal ref={modalRef}>
        <ModalTitle>solicitar dados pessoais</ModalTitle>
        <ModalDescription>
          No SBT valorizamos a sua privacidade e queremos ser muito
          transparentes com você! <br />
          <br /> Por isso, possuímos uma área dedicada para que você faça
          requisições a respeito de seus dados pessoais, conforme previsto na
          Lei Geral de Proteção de Dados Pessoais (Lei 13.709/18 ou “LGPD”).
          <br />
          <br /> Se você quer entender a forma com que tratamos os seus dados
          pessoais, desde o ponto de coleta até a sua eliminação, acesse a nossa
          Política de privacidade,{' '}
          <b className="link" onClick={redirectToPrivacyPolicy}>
            {' '}
            clicando aqui.
          </b>
          <br />
          <br /> Para solicitar uma cópia dos seus dados pessoais, envie sua
          solicitação ao Encarregado de Dados do SBT, pelo e-mail{' '}
          <a href="mailto:dpo@sbt.com.br">dpo@sbt.com.br</a>.
        </ModalDescription>
        <div className="modal-actions">
          <Button
            id="cancel-download-data"
            full
            customColors="btn-white"
            className="mbi-0"
            onClick={closeModal}
          >
            <ButtonLabel>cancelar</ButtonLabel>
          </Button>
        </div>
      </Modal>
    </>
  );
}
