import { dateMask } from '../../utils/Masks';
import FormField from '../FormField/FormField';

interface DateProps {
  errors: any;
  register: any;
  touchedFields: any;
  hasCpf?: boolean;
}

export default function Date({
  errors,
  register,
  touchedFields,
  hasCpf
}: DateProps) {
  return (
    <div>
      <FormField
        name="birthDate"
        label="data de nascimento"
        type="text"
        placeholder="dd/mm/aaaa"
        inputMode="numeric"
        error={errors}
        touchedFields={touchedFields}
        register={register('birthDate', { onChange: dateMask })}
        disabled={hasCpf}
        shouldCapitalizeFirstLetter={false}
      />
    </div>
  );
}
