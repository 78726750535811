import LogoText from '../../components/LogoText/LogoText';
import './Terms.scss';
import { POLICY } from '../../data/Policy';
import { TERMS_OF_USE } from '../../data/Terms';

export enum TERMS_POLICY_TYPES {
  TERMS = 'termos-de-uso',
  POLICY = 'politica-de-privacidade'
}

interface Props {
  type: TERMS_POLICY_TYPES;
}

export default function TermsAndPolicy({ type }: Props) {
  return (
    <>
      <div className="terms-policy-container">
        <div className="title-container">
          <LogoText />
          <div className="terms-policy-container-title">
            <h1>
              {type === TERMS_POLICY_TYPES.POLICY
                ? 'política de privacidade'
                : 'termos de uso'}
            </h1>
          </div>
        </div>
        <div className="terms-policy-container-description">
          <p
            className="md color-text-default"
            dangerouslySetInnerHTML={{
              __html: type === TERMS_POLICY_TYPES.POLICY ? POLICY : TERMS_OF_USE
            }}
          />
        </div>
      </div>
    </>
  );
}
