import FormField from '../FormField/FormField';
import { useEffect, useState } from 'react';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import './SocialName.scss';
import { clearSpaces, maskClearSpecialCharacters } from '../../utils/Masks';
interface SocialNameProps {
  errors: any;
  register: any;
  touchedFields: any;
  labelSocial?: string;
  isInline?: boolean;
  getValues?: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
  hasCpf?: boolean;
}

export default function SocialName({
  errors,
  register,
  touchedFields,
  setValue,
  isInline,
  labelSocial,
  getValues,
  hasCpf
}: SocialNameProps) {
  const [visibleSocialNameField, setVisibleSocialNameField] = useState(false);

  async function changeCheckBox() {
    setValue('socialName', '');
    setVisibleSocialNameField((state) => !state);
  }

  useEffect(() => {
    checkHasSocialName();
  }, []);

  function checkHasSocialName() {
    const timer = setInterval(() => {
      if (getValues && getValues('hasSocialName') == true) {
        clearInterval(timer);
        setVisibleSocialNameField(true);
      } else if (getValues && getValues('userName')) {
        clearInterval(timer);
      }
    }, 100);
  }

  return (
    <div className={`social-name ${isInline ? 'social-name-inline' : ''}`}>
      <div className={isInline ? 'social-name-inline__field-name' : ''}>
        <FormField
          name="userName"
          label="nome completo"
          type="text"
          maxLength="200"
          error={errors}
          touchedFields={touchedFields}
          onKeyUp={maskClearSpecialCharacters}
          onBlur={clearSpaces}
          register={register('userName')}
          placeholder="digite seu nome completo"
          disabled={hasCpf}
        />
        <label htmlFor="hasSocialName" className="check-box mb-12 mt-16">
          <span className="md">Desejo usar um nome social</span>
          <input
            type="checkbox"
            id="hasSocialName"
            name="hasSocialName"
            onClick={changeCheckBox}
            {...register('hasSocialName')}
            disabled={hasCpf}
          />
          <span className="check-box-checked"></span>
        </label>
      </div>

      {visibleSocialNameField ? (
        <div className={isInline ? 'social-name-inline__field-social' : ''}>
          <FormField
            autofocus
            label={labelSocial}
            maxLength="200"
            customClass={labelSocial ? 'mti-32' : 'mti-12'}
            register={register('socialName')}
            name="socialName"
            onKeyUp={maskClearSpecialCharacters}
            onBlur={clearSpaces}
            type="text"
            error={errors}
            touchedFields={touchedFields}
            placeholder="qual é o nome que você prefere?"
            disabled={hasCpf}
          />
        </div>
      ) : null}
    </div>
  );
}
