import { useEffect, RefObject } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useOutsideClickDetector(
  ref: RefObject<HTMLElement>,
  handler: () => void
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) handler();
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ref]);
}
