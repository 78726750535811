import './Button.scss';

interface ButtonProps {
  id?: string;
  disabled?: boolean;
  loading?: boolean;
  full?: boolean;
  customColors?: string;
  className?: string;
  dataCy?: string;
  onClick?: (e: any) => void;
  children: React.ReactNode;
}

export default function Button({
  id,
  disabled,
  full,
  dataCy,
  customColors = 'btn-primary',
  className = '',
  loading,
  onClick,
  ...props
}: ButtonProps) {
  return (
    <button
      id={id ? `${id}-btn` : undefined}
      type="button"
      data-cy={dataCy}
      className={`button ${customColors} ${
        full ? 'btn-full' : ''
      } ${className}`}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {props.children}
    </button>
  );
}

// Ex: implementation
{
  /* <Button
  dataCy="submit"
  customColors="btn-primary"
  loading={loading}
  onClick={handleSubmit(onSubmit)}
  disabled={!isValid}
>
  <ButtonLabel label="Submit" />
</Button>; */
}
