import { ErrorMessages } from '../data/ErrorMessages';
import { ILoginResponse, ISocialLoginRequest } from '../interfaces/user/ILogin';
import { IResponseTokenSocial } from '../interfaces/ISocial';
import {
  getOriginDomain,
  shouldOpenOAuthWithRedirect
} from '../utils/GenericFunctions';
import { POST } from './api';
import { setCookie, getCookie } from './cookieController';

const ACCOUNT_SERVICE = process.env.REACT_APP_BASE_URL_ACCOUNT_SERVICE;
const REACT_APP_APPID_GOOGLE = process.env.REACT_APP_APPID_GOOGLE;
const REACT_APP_SCOPE_GOOGLE_PROFILE =
  process.env.REACT_APP_SCOPE_GOOGLE_PROFILE;
const REACT_APP_SCOPE_GOOGLE_EMAIL = process.env.REACT_APP_SCOPE_GOOGLE_EMAIL;
const CLIENT_ID = process.env.REACT_APP_APPID_APPLE;
const REDIRECT_URI = process.env.REACT_APP_APPID_APPLE_REDIRECT_URI;
const SCOPE_EMAIL = process.env.REACT_APP_SCOPE_APPLE_EMAIL;
const KEY_SESSION = process.env.REACT_APP_KEY_SESSION as string;
const KEY_SESSION_ORIGIN = process.env.REACT_APP_KEY_SESSION_ORIGIN as string;
const KEY_SESSION_SOCIAL = process.env.REACT_APP_KEY_SESSION_SOCIAL as string;
const KEY_SESSION_PROVIDER = process.env
  .REACT_APP_KEY_SESSION_PROVIDER as string;
const KEY_REFRESH_SESSION = process.env.REACT_APP_KEY_REFRESH_SESSION as string;

export async function sendSocialData(
  token: string,
  provider: string,
  email?: string,
  connectToAccount?: boolean
): Promise<IResponseTokenSocial> {
  const payload: ISocialLoginRequest = {
    provider,
    providerToken: token,
    deviceName: 'web'
  };

  const response = await POST(
    `${ACCOUNT_SERVICE}/api/v2/users/login`,
    payload,
    ErrorMessages.LOGIN_SOCIAL
  );
  const data: ILoginResponse = response.data;
  if (!connectToAccount) {
    setCookie(KEY_SESSION, data.accessToken);
    setCookie(KEY_REFRESH_SESSION, data.refreshToken);
    setCookie(KEY_SESSION_SOCIAL, token);
    setCookie(KEY_SESSION_PROVIDER, provider);
    setCookie(KEY_SESSION_ORIGIN, getOriginDomain());
  }
  return {
    ...data
  };
}

export function initGoogleAccount(
  callbackClient: any,
  callback?: any,
  connectAccount?: boolean
) {
  let client;
  const scope = [
    REACT_APP_SCOPE_GOOGLE_PROFILE,
    REACT_APP_SCOPE_GOOGLE_EMAIL,
    'openid'
  ].join(' ');

  const timerInterval = setInterval(() => {
    if (window.google) {
      clearInterval(timerInterval);
      client = window.google.accounts.oauth2.initIdTokenClient({
        client_id: REACT_APP_APPID_GOOGLE,
        scope: scope,
        callback: (response: any) => callback(response, connectAccount)
      });
      callbackClient(client);
    }
  }, 100);
}

export function getAccessTokenGoogle() {
  return getCookie(KEY_SESSION_SOCIAL);
}

export function initFB() {
  function init() {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: process.env.REACT_APP_APPID_FACE,
        cookie: true,
        xfbml: true,
        version: 'v15.0'
      });
    };
    window.fbAsyncInit();
  }

  const checkExistFb = setInterval(() => {
    if (window.FB) {
      clearInterval(checkExistFb);
      init();
    }
  }, 100);
}

export function initApple(redirectURL: string) {
  const appleRedirectUri = shouldOpenOAuthWithRedirect(redirectURL)
    ? `${ACCOUNT_SERVICE}/api/v1/callback/oauth/apple`
    : window.location.origin || REDIRECT_URI;
  window.AppleID.auth.init({
    clientId: CLIENT_ID,
    scope: SCOPE_EMAIL,
    redirectURI: appleRedirectUri,
    usePopup: !shouldOpenOAuthWithRedirect(redirectURL)
  });
}
