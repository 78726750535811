import { useEffect } from 'react';
import Card from '../../../../components/Card/Card';
import CpfComponent from '../../../../components/Cpf';
import FormField from '../../../../components/FormField/FormField';
import FormFieldSelect from '../../../../components/FormField/FormFieldSelect';
import SocialName from '../../../../components/SocialName/SocialName';
import { useUpdateUserDataContext } from '../../../../data/UpdateUserData/UpdateUserDataContext';
import Date from '../../../../components/Date/Date';
import { GENRE } from '../../../../data/Constants';
import { useControlNavBarContext } from '../../../../hooks/ControlNavBar';
import { useNavigate } from 'react-router-dom';

export default function CardPersonalData() {
  const {
    register,
    touchedFields,
    errors,
    getValues,
    setValue,
    visibleFieldGenrer,
    setVisibleFieldGenrer,
    hasCpf,
    sizeView,
    isFormEqual,
    setshowModalPendingDataToSave,
    openModalPendingDataToSave
  } = useUpdateUserDataContext();
  const { setShowButton, setGoBackNavigate } = useControlNavBarContext();
  const navigate = useNavigate();
  const genders = [
    { label: 'masculino', value: GENRE.MALE },
    { label: 'feminino', value: GENRE.FEMALE },
    { label: 'outros', value: GENRE.OTHER }
  ];

  function interceptorGoBack() {
    if (isFormEqual.equal) {
      navigate('/menu-de-conta/atualizar-dados', { state: 'refresh' });
    } else {
      setshowModalPendingDataToSave(true);
      openModalPendingDataToSave();
    }
  }

  function configGoBack() {
    setGoBackNavigate({
      path: '/menu-de-conta/atualizar-dados',
      param: { state: 'refresh' },
      condition: interceptorGoBack
    });
  }

  useEffect(() => {
    configGoBack();
  }, [isFormEqual.equal]);

  useEffect(() => {
    if (sizeView[0] <= 576) {
      setShowButton(true);
      configGoBack();
    }
  }, []);

  function selectGender(item: any) {
    setValue('gender', item.value);
    setVisibleFieldGenrer(item.value === GENRE.OTHER);
    if (item.value !== GENRE.OTHER) {
      setValue('kindGender', '');
    }
  }

  return (
    <Card
      width="100%"
      paddingHorizontal="24"
      paddingVertical="24"
      id="personalData"
    >
      <div className="width-full flex flex-column">
        <div className="width-full">
          <h2 className="color-text-default mb-16">dados pessoais</h2>
          <div className="mt-13 mb-5 flex flex-column">
            <SocialName
              register={register}
              errors={errors}
              touchedFields={touchedFields}
              setValue={setValue}
              getValues={getValues}
              labelSocial="nome social"
              isInline
              hasCpf={hasCpf}
            />
          </div>
          <div className="mt-29 half-field">
            <CpfComponent
              errors={errors}
              touchedFields={touchedFields}
              register={register}
              name={'cpf'}
              disabled={hasCpf}
            />
          </div>
          <div className="mt-40 half-field">
            <Date
              register={register}
              errors={errors}
              touchedFields={touchedFields}
              hasCpf={hasCpf}
            />
          </div>
          <div className="fields-gender">
            <FormFieldSelect
              touchedFields={touchedFields}
              error={errors}
              label="gênero"
              name="gender"
              register={register('gender')}
              items={genders}
              selectValue={selectGender}
            />

            {visibleFieldGenrer ? (
              <FormField
                customClass="mbi-0"
                name="kindGender"
                type="text"
                label="qual o seu gênero?"
                placeholder="digite seu gênero"
                error={errors}
                touchedFields={touchedFields}
                register={register('kindGender')}
              />
            ) : null}
          </div>
        </div>
      </div>
    </Card>
  );
}
