import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import ButtonLabel from '../../components/Button/ButtonLabel';
import Icon from '../../components/Icon/Icon';
import LogoText from '../../components/LogoText/LogoText';
import { useAuthContext } from '../../hooks/AuthContext';
import './Success.scss';
import { useControllerFlowStepsContext } from '../../hooks/ControllerFlowStepsContext';
import { CTV_SUCCESS_PAGE_PATH } from '../../data/Constants';
import { shouldOpenMenuAccount } from '../../utils/GenericFunctions';
interface SuccessSignUpProps {
  context: any;
}

export default function SuccessSignUp({ context }: SuccessSignUpProps) {
  const { originDomainForm } = useContext<any>(context);
  const { redirectURL, redirectToSite } = useAuthContext();
  const { ctvCode } = useControllerFlowStepsContext();
  const url = `/${originDomainForm}/cadastro-concluido`;
  const navigate = useNavigate();

  useEffect(() => {
    navigate(url, { replace: true });
  }, [navigate]);

  function redirect() {
    if (ctvCode) return navigate(CTV_SUCCESS_PAGE_PATH);
    else if (shouldOpenMenuAccount(redirectURL))
      return navigate('/menu-de-conta');
    redirectToSite();
  }
  return (
    <div className="flex flex-column">
      <LogoText />

      <div className="flex flex-column mt-16 success-card">
        <Icon name="success" />
        <h2 className="mti-18 mbi-16 color-text-default">tudo certo!</h2>

        <span className="md mb-24 color-text-default">
          Sua Conta SBT já está pronta.
          <br /> Agora é só aproveitar!
        </span>

        <Button className="mt-8" full onClick={redirect}>
          <ButtonLabel>concluir</ButtonLabel>
        </Button>
      </div>
    </div>
  );
}
