export const TERMS_OF_USE = `
     <p>
        <a href="#paraqueserve">1 - Para que serve esse documento</a><br />
        <a href="#quempodecessar">2 - Quem pode acessar às Plataformas Digitais</a><br />
        <a href="#comocoletamos">3 - Como coletamos e usamos seus dados pessoais</a><br />
        <a href="#servicosepublicidade">4 - Serviços e publicidade de terceiros</a><br />
        <a href="#oquenaodevefazer">5 - O que você não deve fazer</a><br />
        <a href="#quempertence">6 - A quem pertence os conteúdos das Plataformas Digitais</a><br />
        <a href="#comoseraousados">7 - Como serão usados os conteúdos por você criados</a><br />
        <a href="#disponibilidade">8 - Qual a disponibilidade das plataformas digitais</a><br />
        <a href="#sbtresponsabiliza">9 - Pelo que o SBT não se responsabiliza</a><br />
        <a href="#usodasplataformas">10 - Uso das plataformas digitais por menores de idade</a><br />
        <a href="#legislacao">11 - Informações sobre legislação</a><br />
        <a href="#comunicamoscomvoce">12 - Como nos comunicamos com você</a><br />
        <a href="#contato">13 - Entre em contato conosco</a>
    </p>
    <p>
        Bem-vindo ao aos termos e condições de uso do SBT ("Termos") <br />
        <br />
        O SBT preparou estes Termos para ajudar você a entender melhor como utilizar nossas páginas de serviços, sites, aplicativos, softwares e plataformas, aqui chamadas de Plataformas Digitais, incluindo o Site do SBT e o Aplicativo TV SBT.
        <br />
    </p>
    <p id="paraqueserve" style="margin-bottom: 56px;"></p>
    <p><strong>1. Para que serve esse documento</strong></p>
    <blockquote>
        <p>
            <strong>Resumo</strong>. Os Termos abaixo dispostos são importantes quando do uso das Plataformas Digitais do SBT, na medida em que eles determinam o que você pode e o que não deve fazer, explicam os direitos que você nos concede e
            descrevem as regras que todos precisam seguir, incluindo sobre conteúdo gerado. É importante que você dê seu aceite a esse documento, após uma leitura cuidadosa que lhe permita entender todo conteúdo.
        </p>
    </blockquote>
    
    <p><strong>Nossos serviços</strong>. Nossa finalidade é oferecer informações sobre os serviços e conteúdo da programação das várias emissoras de televisão do SBT. Por meio das Plataformas Digitais do SBT, você pode:</p>
    <p>
        - Acessar informações e conteúdos relacionados com a programação do SBT;<br />
        - Interagir com o SBT;<br />
        - Participar de ações promocionais;<br />
        - Inscrever-se para participar em programas ou quadros;<br />
        - Enviar mensagens, fotos e vídeos;<br />
        - Receber contato de nossos parceiros comerciais sobre promoções e conteúdos extras.
    </p>
    
    <p>
        <strong>Aceite</strong>. Ao utilizar as Plataformas Digitais, você atesta que leu, compreendeu e aceita os Termos, além de concordar em ficar vinculado por eles. Caso tenha dúvidas sobre o entendimento a respeito de qualquer disposição,
        entre em contato conosco antes de utilizar nossos serviços por meio dos canais de comunicação aqui mencionados. Se você não concordar (ou não puder cumprir) com os Termos, então você não poderá usar as Plataformas Digitais do SBT nem
        acessar qualquer de seu conteúdo.<br />
    </p>
    <p>
        <strong>Demais documentos</strong>. Alguns serviços oferecidos pelo SBT podem estar sujeitos a termos e condições adicionais especificados pelo SBT ou terceiros periodicamente, os quais serão incorporados nestes Termos e para os quais
        você também poderá ter de dar seu consentimento.
    </p>
    <p id="quempodecessar" style="margin-bottom: 56px;"></p>
    <p><strong>2. Quem pode acessar às Plataformas Digitais</strong></p>
    <blockquote>
        <p>
            <strong>Resumo</strong>. Você pode utilizar todas a funcionalidades das Plataformas Digitais se preencher alguns requistios, criar sua conta e garantir a segurança de seu login e senha. Não se esqueça de dar seu consentimento ao fim
            do cadastro, quando poderá escolher algumas das preferências de privacidade.
        </p>
    </blockquote>
    <p>
        Requisitos. Para usar as Plataformas Digitais do SBT por meio de seu smartphone, desktop, laptop ou tablet e acessar qualquer conteúdo, você precisa (a) cumprir os requisitos de idade da tabela abaixo; (b) possuir capacidade jurídica
        para celebrar um contrato vinculativo conosco e participar das ações promocionais e dos quadros interativos; e (c) residir em um país onde as Plataformas Digitais estiverem disponíveis. Para utilizar todas as nossas funcionalidades,
        pode ser necessário que você realize seu cadastro criando login e senha e apresentando dados cadastrais obrigatórios:
    </p>
    <div style="overflow-x: auto;">
        <table cellpadding="5" style="word-break: initial; font-size: 16px; color: #333; width: 100%; border-collapse: collapse;">
            <tbody>
                <tr>
                    <td style="border: 1px solid #333;" valign="top"><b>Tipo</b></td>
                    <td style="border: 1px solid #333;" valign="top"><b>Conteúdo</b></td>
                    <td style="border: 1px solid #333;" valign="top"><b>Plataforma</b></td>
                    <td style="border: 1px solid #333;" valign="top"><b>Dados coletados</b></td>
                    <td style="border: 1px solid #333;" valign="top"><b>Critério</b></td>
                </tr>
                <tr>
                    <td style="border: 1px solid #333;" valign="top">Sem cadastro</td>
                    <td style="border: 1px solid #333;" valign="top">Visualizar vídeos notícias e conteúdos</td>
                    <td style="border: 1px solid #333;" valign="top">Site</td>
                    <td style="border: 1px solid #333;" valign="top">Cookies</td>
                    <td style="border: 1px solid #333;" valign="top">Usuário a partir de 13 anos ou menor de 13 anos com consentimento dos pais ou responsáveis</td>
                </tr>
                <tr>
                    <td style="border: 1px solid #333;" valign="top">Com cadastro</td>
                    <td style="border: 1px solid #333;" valign="top">
                        Visualizar vídeos notícias e conteúdo, realizar inscrições em programas, participar de ações promocionais ou quadros interativos organizados, personalizar conteúdos favoritos
                    </td>
                    <td style="border: 1px solid #333;" valign="top">Site e App</td>
                    <td style="border: 1px solid #333;" valign="top">Nome completo, gênero, e-mail, data de nascimento, CPF, RG, número de celular, endereço e profissão</td>
                    <td style="border: 1px solid #333;" valign="top">Usuário a partir de 18 anos, desde que considerado civilmente capaz</td>
                </tr>
            </tbody>
        </table>
    </div>
    <p>
        <strong>Com cadastro</strong> Visualizar vídeos notícias e conteúdo, realizar inscrições em programas, participar de ações promocionais ou quadros interativos organizados, personalizar conteúdos favoritos Site e App Nome completo,
        gênero, e-mail, data de nascimento, CPF, RG, número de celular, endereço e profissão Usuário a partir de 18 anos, desde que considerado civilmente capaz
    </p>
    <p>
        <strong>Cadastro</strong>. Quando se cadastrar, você deverá fornecer informações exatas, precisas, autênticas e verdadeiras, assumindo o compromisso de atualizar os dados pessoais sempre que neles ocorrer alguma alteração. Você definirá
        o login e senha com os quais poderá acessar as páginas de consulta de status e de alteração do cadastro. Os dados fornecidos para o cadastro nas Plataformas Digitais do SBT, bem como que seu login e senha, são de natureza confidencial,
        individual e intransferível, não devendo ser fornecidos a terceiros, isentando-se o SBT de qualquer responsabilidade por danos e prejuízos decorrentes do uso indevido destes.
    </p>
    <p>
        <strong>Senha e login</strong>. Sua senha protege sua conta de usuário e você é unicamente responsável por mantê-los em confidencialidade e segurança. Você entende que é responsável por todo o uso (incluindo qualquer utilização não
        autorizada) do seu login e senha nas Plataformas Digitais. Caso seu login ou senha seja perdido ou roubado, ou caso você acredite que houve um acesso não autorizado à sua conta por um terceiro, você deve nos notificar imediatamente e
        alterar sua senha assim que possível. Para alterá-la, basta clicar em "Gerar Nova Senha" que será enviado um e-mail no endereço cadastrado, possibilitando que você cadastre nova senha volte a acessar a área.
    </p>
    <p>
        <strong>Check-box</strong>. O cadastro só será finalizado quando você der o aceite nas Plataformas Digitais por meio de seleção do check-box correspondente, ocasião em que você demonstrará o seu
        <strong> consentimento de forma livre, expressa, individual, clara, específica e legítima</strong>, manifestando, de maneira precisa, sua concordância com o conteúdo destes Termos e da Política de Privacidade.
    </p>
    <p>
        <strong>Uso devido</strong>. Você deve garantir que sua utilização das Plataformas Digitais estará de acordo com Termos e em conformidade com todas as leis, normas e regulamentos aplicáveis. Você promete e concorda que está usando as
        Plataformas Digitais para seu próprio uso pessoal e que este uso não possui qualquer destinação. As Plataformas Digitais do SBT não são vendidas ou transferidas para você, sendo que o SBT e seus licenciantes retêm a titularidade de
        todas as cópias das Plataformas Digitais, mesmo após a instalação em seus dispositivos eletrônicos.
    </p>
    <p>
        <strong>Fraude</strong>. Em caso de suspeita de ocorrência de fraude, o seu acesso poderá ser suspenso ou bloqueado a qualquer tempo, sem que nada lhe seja devido em decorrência deste ato. As decisões do SBT quanto às escolhas das
        inscrições serão soberanas e irrecorríveis.
    </p>
    <p>
        <strong>Envio de comunicação</strong>. Você poderá, no momento do cadastro, autorizar o envio material publicitário, informativos, indicação de assuntos de interesse, conteúdos promocionais, por parte do SBT, das demais empresas do
        Grupo Silvio Santos ou por seus parceiros comerciais e operacionais, por meio de seleção do check-box correspondente.
    </p>
    <p>
        <strong>Recusa</strong>. O SBT pode, a seu exclusivo critério e a qualquer momento, recusar-se a oferecer o serviço a qualquer pessoa, bem como pode alterar seus critérios de elegibilidade, recusar o cadastro de usuário ou cancelar uma
        conta. Nós também podemos remover, recusar ou inviabilizar qualquer conteúdo, assim como suspender ou encerrar contas de usuários, sem que isso gere qualquer responsabilidade perante você ou outros usuários, em especial, em caso de
        prática de algum ato ou conduta que viole (a) regras destes Termos, (b) princípios da moral e dos bons costumes e (c) qualquer legislação aplicável.
    </p>
    <p id="comocoletamos" style="margin-bottom: 56px;"></p>
    <p><strong>3. Como coletamos e usamos seus dados pessoais</strong></p>
    <blockquote>
        <p><strong>Resumo</strong>. Seus dados pessoais serão coletados direta e indiretamente pela empresa SBT, cuja Política de Privacidade deverá ser lida e aceita por você.</p>
    </blockquote>
    <p>
        <strong>Sua privacidade</strong>. No SBT, estamos comprometidos em resguardar sua privacidade. Quando usar nossas Plataformas Digitais, você poderá interagir com conteúdo e pessoas e, para que tenha uma melhor experiência de navegação,
        você poder nos fornecer alguns dados pessoais. Ainda assim, você poderá sempre controlar as informações compartilhadas conosco. Ao aceitar estes Termos, você autoriza o SBT a utilizar os dados da forma descrita na Política de
        Privacidade. Para mais detalhes, clique aqui.
    </p>
    <p id="servicosepublicidade" style="margin-bottom: 56px;"></p>
    <p><strong>4. Serviços e publicidade de terceiros</strong></p>
    <blockquote>
        <p><strong>Resumo</strong>. Você pode ser direcionado a serviço de terceiro, como por exemplo uma página na internet com informações sobre temas de sua preferência. Você está ciente que não temos controle sobre esses sites.</p>
    </blockquote>
    <p>
        <strong>Seleção de conteúdo</strong>. Nas Plataformas Digitais do SBT, você poderá acessar conteúdo influenciado por considerações de natureza comercial, incluindo os acordos do SBT com terceiros. Alguns conteúdos licenciados,
        fornecidos, criados ou de qualquer forma disponibilizados pelo SBT podem conter publicidade.
    </p>
    <p>
        <strong>Serviços terceiros</strong>. Nossas Plataformas Digitais podem direcionar você para outros sites e/ou recursos fornecidos por terceiros, os quais você usará por sua conta e risco, devendo consentir com os respectivos documentos
        dos parceiros. Nada aqui deve ser interpretado como aprovação por nós daqueles sites vinculados ou informações que você pode obter deles. Você reconhece que não nos responsabilizamos pelo conteúdo, exatidão, legalidade ou qualquer outro
        aspecto de tais parceiros. Note que, para oferecer alguns de nossos serviços, pode ser utilizado o player de vídeo incorporado do YouTube (“YouTube Player”), independentemente de qualquer cadastro. O YouTube pode coletar dados quando 
		você usa, interage ou se envolve com o YouTube Player através das Plataformas Digitais do SBT. Nestes casos, você pode saber mais sobre a Política de Privacidade do Google em relação ao YouTube Player aqui: <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>
    </p>
    <p>
        <strong>Anúncios</strong>. Você concorda que o SBT e seus parceiros podem colocar anúncios nas Plataformas Digitas ou em conexão com a exibição de conteúdo ou de informações enviados por você ou por terceiros. Nossos parceiros podem
        compartilhar conosco algumas de suas informações pessoais, em especial dados de navegação, localização e interesse, permitindo que os combinemos com dados pessoais que já coletados para o fornecimento de anúncios mais customizados e
        eficazes. Ao usar nossas Plataformas Digitais, você concorda que podemos mostrar-lhe anúncios que consideramos relevantes para você e seus interesses, utilizando parte de seus dados pessoais. A receita oriunda de publicidade permite ao
        SBT melhor prestar nossos serviços.
    </p>
    <p id="oquenaodevefazer" style="margin-bottom: 56px;"></p>
    <p><strong>5. O que você não deve fazer</strong></p>
    <blockquote>
        <p>
            <strong>Resumo</strong>. Não utilize nossas Plataformas Digitais de forma diversa do que consta nesse documento, como por exemplo tentar realizar nelas qualquer alteração, incluir conteúdo inverídico ou de terceiros sem autorização,
            assim como enviar spam ou vírus.
        </p>
    </blockquote>
    <p>
        <strong>O que você não deve fazer</strong>: Como condição de uso, você deve usar o serviço de acordo com estes Termos e a legislação aplicável. Caso não o faça, nós podermos advertir, suspender ou cancelar, temporária ou
        definitivamente, sua conta a qualquer tempo, e iniciar as ações legais cabíveis. Por exemplo, você não deve (direta ou indiretamente):
    </p>
    <p></p>
    <p>
        <strong>a)</strong> Interferir nas Plataformas Digitais, ou corromper os dados nela contidos; <br />
        <strong>b)</strong> Inserir qualquer conteúdo ou material capaz de incorporar elementos nocivos nas Plataformas Digitais por quaisquer meios e formas capazes de impedir seu normal funcionamento, prejudicar os sistemas informáticos do
        SBT ou danificar documentos e/ou arquivos eletrônicos nestes armazenados<br />
        <strong>c)</strong> Fazer uso indevido das Plataformas Digitais e das informações nelas disponíveis ou manter conduta que viole as leis e regulamentos federais, estaduais e/ou municipais ou contrarie as regras destes Termos;<br />
        <strong>d)</strong> Violar direitos de propriedade intelectual ou industrial de terceiros;<br />
        <strong>e)</strong> Causar danos a terceiros ou a nós, como por exemplo utilizar as Plataformas Digitais com qualquer propósito ilegal, calunioso, difamatório, desrespeitoso, ameaçador, discriminatório e/ou inverídico;<br />
        <strong>f)</strong> Ignorar quaisquer medidas que possam ser usadas por nós para evitar ou restringir acesso às Plataformas Digitais;<br />
        <strong>g)</strong> Executar qualquer resposta automática ou spam nas Plataformas Digitais; <br />
        <strong>h)</strong> Modificar, adaptar, apropriar, reproduzir, distribuir, traduzir, criar obras derivadas ou adaptações, exibir publicamente, republicar, reapropriar, vender, comercializar ou de alguma forma explorar as Plataformas
        Digitais ou qualquer parte de seu conteúdo;<br />
        <strong>i)</strong> Não induzir, incitar ou incorrer em atitudes: discriminatórias, violentas, ilegais ou delituosas; que atentem contra moral e bons costumes; que assediem ou invadam a privacidade alheia, seja sexualmente explícita,
        obscena, racial ou etnicamente ofensiva; que contrariam a ordem pública; ou que, de qualquer outra forma, possam causar riscos ou danos de imagem, materiais ou morais a nós, nossos empregados ou qualquer um dos seus colaboradores ou
        terceiros.
    </p>
    <p id="quempertence" style="margin-bottom: 56px;"></p>
    <p><strong>6. A quem pertence os conteúdos das Plataformas Digitais</strong></p>
    <blockquote>
        <p>
            <strong>Resumo</strong>. As marcas e direitos referentes às Plataformas Digitais são de nossa titularidade ou estamos licenciados para tal. Você não poderá fazer uso das marcas sem nossa autorização, tampouco terá quaisquer direitos
            de propriedade sobre as Plataformas Digitais.
        </p>
    </blockquote>
    
    <p>
        <strong>Titularidade do conteúdo.</strong> As Plataformas Digitais do SBT e todo conteúdo nelas diponível ? tais como marcas, textos, imagens, fotografias, vídeos, ilustrações, ícones, tecnologias, links e demais conteúdos audiovisuais
        ou sonoros - são de propriedade do SBT ou dos licenciantes do SBT ("Conteúdo").
    </p>
    <p>
        <strong>Permissão de acesso</strong>. Nós concedemos a você uma permissão limitada, não exclusiva e revogável para fazer uso pessoal e não comercial das Plataformas Digitais e do Conteúdo durante o período em que estejam online. É
        vedada a cópia, reprodução ou qualquer outro tipo de utilização do Conteúdo fora das condições aqui estabelecidas ou sem consentimento prévio e expresso do SBT, ficando os infratores sujeitos às sanções civis e criminais
        correspondentes.
    </p>
    <p>
        <strong>Sistemas</strong>. Nós possuímos todos os direitos, incluindo os direitos de propriedade intelectual, ou estamos devidamente licenciados pelo titular, referentes às Plataformas Digitais, tais como o código-fonte, patentes,
        direitos autorais, segredos industriais e outros estejam ou não especificadamente reconhecidos ou legalmente constituídos. Os Termos não lhe transmitem quaisquer direitos de propriedade ou relacionados às Plataformas Digitais, ou
        qualquer outro direito de propriedade intelectual de software.
    </p>
    <p>
        <strong>Alterações</strong>. São vedados quaisquer atos ou contribuições tendentes à descompilação, engenharia reversa, modificação das características, ampliação, alteração, mesclagem ou incorporação nas Plataformas Digitais do SBT ou
        em quaisquer outros programas e sistemas.
    </p>
    <p id="comoseraousados" style="margin-bottom: 56px;"></p>
    <p><strong>7. Como serão usados os conteúdos por você criados</strong></p>
    <blockquote>
        <p>
            <strong>Resumo</strong>. Você é titular apenas do conteúdo por você criado, pelo qual é exclusivamente responsável, mas nos dá uma licença de uso deste material para uso e interações nas Plataformas Digitais. Você será responsável
            por nos indenizar em caso de sofrermos alguma perda resultante do seu uso das Plataformas Digitais. Fique atento a conteúdo abusivo, impróprio ou ilícito.
        </p>
    </blockquote>
    <p>
        <strong>Conteúdo de usuários.</strong> Você e os demais usuários das Plataformas Digitais do SBT podem postar, carregar ou contribuir de forma diversa com conteúdo para as Plataformas Digitais (o que pode incluir, por exemplo, fotos,
        texto, mensagens, informações, títulos de listas de execução, descrições e compilações e/ou outros tipos de conteúdo) ("Conteúdo de Usuário") nas Plataformas Digitais do SBT.
    </p>
    <p>
        <strong>Sua responsabilidade</strong>. Você deve estar ciente de que é o único e exclusivo responsável pelo seu Conteúdo de Usuário, quando de seu acesso às Plataformas Digitais do SBT, incluindo inscrições em promoções e em quadros
        interativos e de seus efeitos, responsabilizando-se, inclusive perante terceiros, por quaisquer danos ou prejuízos decorrentes de sua utilização das Plataformas Digitais do SBT.
    </p>
    <p>
        <strong>Infração de direitos</strong>. Você garante que tem todos os direitos, poderes e autoridade necessários para cumprimento desses Termos e da legislação aplicável, declarando que qualquer Conteúdo de Usuário não infringe nenhum
        direito de propriedade intelectual, de autor, de marca, de patente ou qualquer outro direito de terceiro, além de garantir que tal informação não tem caráter confidencial e que não é prejudicial a terceiros. Você aceita e se
        responsabiliza pelo seu Conteúdo de Usuário enviado ao SBT, respondendo por qualquer reivindicação que venha a ser apresentada ao SBT, judicial ou extrajudicialmente, em relação a direitos patrimoniais, propriedade intelectual e/ou aos
        direitos de imagem, ou ainda por danos morais e/ou materiais causados ao SBT ou a terceiros.
    </p>
    <p>
        <strong>Licença de uso</strong>. Ainda que você retenha a titulariade de todos os direitos, inclusive direitos de propriedade intelectual, relativos ao Conteúdo de Usuário, ao fazer upload deste conteúdo nas Plataformas Digitais, você
        nos outorga uma licença mundial gratuita, não-exclusiva, transferível, sub licenciável, isenta de royalties e perpétua para utilizar, copiar, reproduzir, processar, adaptar, modificar, publicar, transmitir, exibir e distribuir esse
        Conteúdo de Usuário por meio de qualquer meio, seja individualmente ou em combinação com outros Conteúdo de Usuário ou materiais, de qualquer maneira e por qualquer meio, método ou tecnologia, atualmente conhecidos ou posteriormente
        criados.
    </p>
    <p>
        <strong>Feedback</strong>. Você concede e, se aplicável, renuncia em favor do SBT, assim como em favor de outras empresas, organizações ou pessoas parceiras do SBT, todos os direitos, titularidades e participações relacionadas a: (a)
        quaisquer modificações ou melhorias nos sistemas realizadas por ou para você; (b) qualquer invenção ou criação que se baseie ou utilize todo ou qualquer parte das Plataformas Digitais; e (c) qualquer relatório, comentário ou outras
        informações referentes aos sistemas nos fornecidos por você.
    </p>
    <p>
        <strong>Conteúdo abusivo</strong>. Você pode ser exposto a Conteúdo ofensivo, prejudicial, impreciso, inadequado ou fraudulento, o qual o SBT pode - mas não tem a obrigação de - monitorar, revisar, editar e excluir, a nosso exclusivo
        critério e sem aviso prévio.
    </p>
    <p>
        <strong>Violação de conteúdo</strong>. O SBT poderá, a seu exclusivo critério, remover qualquer Conteúdo das Plataformas Digitais ou tomar outras medidas, caso considere necessário, sem prévia notificação, que algum contéudo viola
        direitos do próprio SBT, desses Termos ou de terceiros. Qualquer usuário ou outra parte que acredite que determinado Conteúdo não é ilícito, poderá contra-notificar ao SBT com um pedido para restaurar o conteúdo removido, que o SBT
        poderá ou não cumprir, a seu critério exclusivo. Se você acredita que qualquer Conteúdo viola seus direitos de propriedade intelectual ou outros direitos, entre em contato conosco.
    </p>
    <p id="disponibilidade" style="margin-bottom: 56px;"></p>
    <p><strong>8. Qual a disponibilidade das plataformas digitais</strong></p>
    <blockquote>
        <p>
            <strong>Resumo</strong>. É direito do SBT gerir os serviços, mas não garantimos que eles estejam disponíveis 100% do tempo. Estamos sempre tentando melhorá-los, mas poderemos os suspender, limitar ou descontinuar. Lembre-se que para
            usar nossas plataformas digitais você deverá possuir uma rede de internet.
        </p>
    </blockquote>
    <p>
        <strong>Evolução dos serviços</strong>. As Plataformas Digitais do SBT estão disponíveis aos usuários por tempo indeterminado. Contudo, o SBT pode, a qualquer tempo, descontinuá-los, sem necessidade de aviso prévio, ou ainda limitar seu
        acesso, sendo possível negar ou suspender, em caso de suspeita de uso indevido ou ilícito, o que pode motivar a exclusão de todas as informações fornecidas, sem que qualquer indenização ou compensação seja devida.
    </p>
    <p><strong>Disponibilidade</strong>. As Plataformas Digitais estarão disponíveis no período diurno e noturno, em dias úteis, finais de semana e feriados.</p>
    <p>
        <strong>Incompletude dos serviços.</strong> Nosso objetivo é fornecer-lhe acesso ininterrupto aos nossos serviços a qualquer momento, mas não podemos garantir que estarão sempre disponíveis. Você fica ciente de que as Plataformas
        Digitais compreendem um sistema em constante desenvolvimento, a ser utilizado por você sob seu próprio risco nos termos deste documento. Nós não garantimos que: (a) o serviço seja seguro, livre de erros, ininterrupto ou disponível a
        qualquer determinado momento ou local, incluindo o funcionamento dos serviços prestados pela empresa de telefonia; (b) qualquer defeito ou erro será corrigido; (c) qualquer conteúdo ou software disponível em razão do serviço seja livre
        de vírus ou outros componentes prejudiciais; e (d) os resultados do uso do serviço irão satisfazer suas exigências.
    </p>
    <p>
        <strong>Funcionalidades</strong>. Como estamos constantemente melhorando nossos serviços, podemos adicionar e remover funcionalidades ou fazer outras alterações periodicamente. Podemos suspender as Plataformas Digitais temporariamente,
        interromper seu funcionamento para manutenções técnicas, atualizações, otimizações ou atendendo a determinações de quaisquer órgãos públicos ou cancelá-lo a seu critério, a qualquer momento, independentemente de aviso prévio a usuários.
    </p>
    <p>
        <strong>Defeitos</strong>. Você é responsável por configurar seus dispositivos para acessar e/ou usar nossas Plataformas Digitais, devendo seguir nossos conselhos ou orientações para mitigar quaisquer danos. Caso você tenha algum
        problema com o uso das Plataformas Digitais, você pode entrar em contato conosco.
    </p>
    <p>
        <strong>Plano de dados</strong>. Caso você tenha de usar uma conexão de dados, os custos oriundos dos planos de tarifas da operadora de telefonia deverão ser por você cobertos. O SBT não será responsável pelas conexões ou pacotes de
        dados, tampouco pela instabilidade da rede de internet, por falhas de conectividade, por interrupções ocasionadas por empresas provedoras de internet, pela prática de spam ou pela suspensão de serviços de internet, falhas em
        equipamentos.
    </p>
    <p>
        <strong>Riscos</strong>. É importante que você saiba e esteja ciente dos problemas e riscos na utilização da Internet. Desta forma, você é o único responsável por se prevenir face à existência destes riscos, que podem acarretar
        prejuízos financeiros, perda de dados, perdas financeiras, contaminação ocasionadas por vírus ou ações de invasão de hackers.
    </p>
    <p id="sbtresponsabiliza" style="margin-bottom: 56px;"></p>
    <p><strong>9. Pelo que o SBT não se responsabiliza</strong></p>
    <blockquote>
        <p>
            <strong>Resumo</strong>. Não somos responsáveis por falhas, indisponibilidades e incompletude dos serviços nas Plataformas Digitais. Tampouco endossamos os conteúdos nelas existentes. Seremos responsáveis apenas pelos danos diretos
            referentes às nossas responsabilidades.
        </p>
    </blockquote>
    
    <p><strong>Endosso dos conteúdos</strong>. Não aprovamos, apoiamos, declaramos nem garantimos a integridade, veracidade, exatidão ou confiabilidade de qualquer Conteúdo de Usuário, tampouco endossamos opiniões expressadas por usuários.</p>
    <p><strong>Responsabilidade do SBT.</strong> Sem prejuízo das demais isenções de responsabilidade indicadas nos presentes Termos, você deve estar ciente de que o SBT não se responsabiliza:</p>
    <p><strong>a)</strong> Por implementar medidas que possam ser usadas por nós para evitar ou restringir acesso às Plataformas Digitais;</p>
    <p>
        <strong>b)</strong> Por quaisquer indisponibilidades, erros e/ou falhas eventualmente apresentadas pelas Plataformas Digitais do SBT, inclusive por qualquer defraudação da sua utilização que possa atribuir ao website, pela sua
        falibilidade, ou por qualquer dificuldade de acesso enfrentada;
    </p>
    <p>
        <strong>c)</strong> Por eventuais erros e/ou inconsistências na transmissão de dados, bem como relacionados à qualidade ou disponibilidade da conexão de Internet, capazes de obstar o adequado recebimento de informações por você ou pelo
        SBT;<br />
        <strong>d)</strong> Por dados desatualizados, incompletos e/ou inverídicos eventualmente apresentados por meio das Plataformas Digitais do SBT;
    </p>
    <p><strong>e)</strong> Pelo uso das Plataformas Digitais do SBT em desacordo com o disposto nestes Termos;</p>
    <p><strong>f)</strong> Por qualquer dano ou prejuízo eventualmente suportado por você, decorrente da utilização, dos produtos ou serviços oferecidos pelo SBT por meio de suas Plataformas Digitais;</p>
    <p>
        <strong>g)</strong> Pelos danos e prejuízos de qualquer natureza, decorrentes do conhecimento que terceiros não autorizados possam ter de quaisquer dados fornecidos, por meio das Plataformas Digitais do SBT, em decorrência de falha
        exclusivamente relacionada a determinado usuário ou a terceiros que fujam de qualquer controle razoável do SBT.
    </p>
    <p><strong>Limitação de responsabilidade. </strong>Não seremos responsáveis por:</p>
    <p><strong>a)</strong> Dano indireto e emergentes;</p>
    <p><strong>b)</strong> Lucros cessantes;</p>
    <p><strong>c)</strong> Perda de lucros, vendas, negócios ou receita;</p>
    <p><strong>d)</strong> Perda de oportunidade ou interrupção de negócio;</p>
    <p id="usodasplataformas" style="margin-bottom: 56px;"></p>
    <p><strong>10. Uso das plataformas digitais por menores de idade</strong></p>
    <blockquote>
        <p style="margin-left: 8px;">
            <strong>Resumo</strong>. Menores de idade podem participar de algumas promoções comerciais, sorteios e concursos, desde que mediante a autorização dos pais ou responsáveis, que devem garantir a veracidade dos dados fornecidos.
        </p>
    </blockquote>
    <p>
        <strong>Participação de menores.</strong> Para se cadastrar nas Plataformas Digitais, você precisa ter, no mínimo, 18 anos de idade. No entanto, há promoções comerciais, sorteios e concursos realizados pelo SBT, bem como inscrições para
        participação de programas, que são abertos para menores de 18 anos. Neste caso, a inscrição do menor deverá ser feita exclusivamente por meio do cadastro de um dos pais ou do responsável legal.
    </p>
    <p>
        <strong>Uso de dados de menores</strong>. Para garantir a máxima proteção à criança ou ao adolescente, somente serão solicitados os dados indispensáveis para a realização da inscrição específica, tais como nome do menor, idade, foto,
        entre outros.
    </p>
    <p><strong>Veracidade dos dados</strong>. Ao realizar o cadastro de criança ou adolescente, você assume a veracidade dos dados fornecidos, bem como a condição de responsável legal do menor.</p>
    <p id="legislacao" style="margin-bottom: 56px;"></p>
    <p><strong>11. Informações sobre legislação</strong></p>
    <blockquote>
        <p><strong>Resumo</strong>. A legislação brasileira é aplicável a estes Termos e o foro é o da comarca da cidade de Osasco/SP.</p>
    </blockquote>
    <p></p>
    <p>
        <strong>Nulidade ou incompletude de cláusulas</strong>. Qualquer cláusula ou condição destes Termos que venha a ser reputada nula ou ineficaz por qualquer juízo ou tribunal, não afetará a validade das demais disposições, as quais
        permanecerão plenamente válidas e vinculantes. Nossa falha em exigir quaisquer direitos ou disposições dos presentes Termos não constituirá renúncia, podendo exercer regularmente o seu direito, dentro dos prazos legais.
    </p>
    <p><strong>Legislação aplicável</strong>. A presente relação jurídica é regida exclusivamente pelas leis brasileiras, inclusive eventuais ações decorrentes de violação dos seus termos e condições.</p>
    <p><strong>Foro</strong>. Fica eleito o Foro da Comarca de Osasco/SP para dirimir quaisquer dúvidas, questões ou litígios decorrentes dos presentes Termos, renunciando as partes a qualquer outro, por mais privilegiado que seja.</p>
    <p id="comunicamoscomvoce" style="margin-bottom: 56px;"></p>
    <p><strong>12. Como nos comunicamos com você</strong></p>
    <blockquote>
        <p style="margin-left: 8px;">
            <strong>Resumo</strong>. Podemos entrar em contato com você por meio do e-mail por você cadastrado. Lembre-se que SBT jamais encaminhará e-mails solicitando o fornecimento de informações financeiras ou pagamento de valores.
        </p>
    </blockquote>
    <p>
        <strong>Contato</strong>. Você concorda que, para ficar ciente de informações importantes relativas à sua conta e manter a comunicação com o SBT, o endereço de e-mail cadastrado será o canal de comunicação utilizado. Se houver alteração
        dos dados registrados após cadastro da conta, estes deverão ser comunicados, imediatamente, ao SBT. Você deverá adotar todas as medidas necessárias (i.e., configuração de programas AntiSpam e filtros de e-mails) para assegurar que os
        e-mails que forem enviados pelo SBT serão entregues ao endereço de e-mail cadastrado.
    </p>
    <p>
        <strong>Solicitações via e-mail</strong>. As Plataformas Digitais do SBT jamais encaminharão e-mails solicitando informações financeiras ou pagamento de boletos. Caso receba quaisquer mensagens desta natureza, você deverá entrar em
        contato imediatamente com o SBT.
    </p>
    <p id="contato" style="margin-bottom: 56px;"></p>
    <p><strong>13. Entre em contato conosco</strong></p>
    <blockquote>
        <p><strong>Resumo</strong>. Não deixe de entrar contato conosco: <strong>ola@SBTcomVC.com.br</strong>.</p>
    </blockquote>
    <p></p>
    <p>Fale conosco. Se você precisar de tirar dúvidas sobre nossas Plataformas Digitais, você deverá entrar em contato conosco por meio do e-mail <strong>ola@SBTcomVC.com.br</strong>.</p>
`;
