import axios from 'axios';
import { deleteCookie, getCookie, setCookie } from './cookieController';
import {
  getOriginDomain,
  getUrlSignIn,
  isWebview
} from '../utils/GenericFunctions';
import { executeRecaptcha } from '../utils/Recaptcha';

import { ErrorMessages, IErrorMessage } from '../data/ErrorMessages';
import { sendSentryMessage } from '../utils/Sentry';
const SSO_SERVICE = process.env.REACT_APP_BASE_URL_SSO_SERVICE;
const KEY_SESSION = process.env.REACT_APP_KEY_SESSION as string;
const KEY_REFRESH_SESSION = process.env.REACT_APP_KEY_REFRESH_SESSION as string;
const KEY_SESSION_SOCIAL = process.env.REACT_APP_KEY_SESSION_SOCIAL as string;
const KEY_SESSION_PROVIDER = process.env
  .REACT_APP_KEY_SESSION_PROVIDER as string;
const KEY_SESSION_ORIGIN = process.env.REACT_APP_KEY_SESSION_ORIGIN as string;
const API_AUTH_TOKEN = process.env.REACT_APP_API_AUTH_TOKEN as string;

const api = axios.create({});

api.interceptors.request.use(async (config: any) => {
  const token = getCookie(KEY_SESSION);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  config.headers['X-Auth-Token'] = `Bearer ${API_AUTH_TOKEN}`;
  config.headers['Origin-SBT'] = getOriginDomain();

  try {
    config.headers['X-Recaptcha-Token'] = await executeRecaptcha();
  } catch (e) {
    console.error(e);
    config.headers['X-Recaptcha-Token'] = `${e}`;
  }

  return config;
});

api.interceptors.response.use(
  async (response: any) => {
    return response;
  },
  async (error) => {
    const originalRequest: any = error.config;
    const token = getCookie(KEY_SESSION);
    const currentRefreshToken: any = getCookie(KEY_REFRESH_SESSION);

    const statusCode = error?.response?.status;
    const url: string = error?.response?.config?.url;

    if (statusCode === 401) {
      if (token && !originalRequest._retry && !url?.includes('/user/token')) {
        originalRequest._retry = true;
        await refreshToken(currentRefreshToken);
        return api(originalRequest);
      } else closeSession();
    }

    return Promise.reject(error);
  }
);

function changeRefreshToken(accessToken: string, refreshToken: string) {
  try {
    const stringParam = JSON.stringify({ accessToken, refreshToken });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const YoutubePlayerView = window.YoutubePlayerView;
    YoutubePlayerView.changeRefreshToken(stringParam);
  } catch (error) {
    console.log(error);
  }
}

export async function refreshToken(token: string) {
  try {
    console.log('refresh');
    const response = await POST(
      `${SSO_SERVICE}/api/v1/user/token`,
      {
        token,
        origin: getOriginDomain()
      },
      ErrorMessages.REFRESH_TOKEN
    );

    const data = response.data;
    setCookie(KEY_SESSION, data.accessToken);
    setCookie(KEY_REFRESH_SESSION, data.refreshToken);
    if (data.refreshToken)
      changeRefreshToken(data.accessToken, data.refreshToken);
    return data;
  } catch (error) {
    console.error(error);
    closeSession();
    throw error;
  }
}

function onCloseSession() {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const YoutubePlayerView = window.YoutubePlayerView;
    YoutubePlayerView.onCloseSession();
  } catch (error) {
    console.log(error);
  }
}

export function closeSession() {
  deleteCookie(KEY_SESSION);
  deleteCookie(KEY_REFRESH_SESSION);
  deleteCookie(KEY_SESSION_SOCIAL);
  deleteCookie(KEY_SESSION_PROVIDER);
  deleteCookie(KEY_SESSION_ORIGIN);

  if (isWebview()) onCloseSession();

  const urlSignIn = getUrlSignIn();
  window.open(urlSignIn, '_self');
}

export async function POST(
  url: any,
  body: object,
  customErrorMessage?: IErrorMessage
) {
  try {
    const response: any = await api.post(url, body);
    return response;
  } catch (error: any) {
    console.log(error);
    if (customErrorMessage) sendSentryMessage(customErrorMessage, error);
    error.response.data.statusCode = error?.response?.status;
    throw error?.response?.data;
  }
}

export async function GET(url: any, customErrorMessage?: IErrorMessage) {
  try {
    const response: any = await api.get(url);
    return response;
  } catch (error: any) {
    if (customErrorMessage) sendSentryMessage(customErrorMessage, error);
    error.response.data.statusCode = error?.response?.status;
    throw error?.response?.data;
  }
}

export async function PUT(
  url: any,
  body: object,
  customErrorMessage?: IErrorMessage
) {
  try {
    const response: any = await api.put(url, body);
    return response;
  } catch (error: any) {
    if (customErrorMessage) sendSentryMessage(customErrorMessage, error);
    throw error?.response?.data;
  }
}

export async function PATCH(
  url: any,
  body: object,
  customErrorMessage?: IErrorMessage
) {
  try {
    const response: any = await api.patch(url, body);
    return response;
  } catch (error: any) {
    if (customErrorMessage) sendSentryMessage(customErrorMessage, error);
    error.response.data.statusCode = error?.response?.status;
    throw error?.response?.data;
  }
}

export async function DELETE(
  url: any,
  body: object,
  customErrorMessage?: IErrorMessage
) {
  try {
    const response: any = await api.delete(url, body);
    return response;
  } catch (error: any) {
    if (customErrorMessage) sendSentryMessage(customErrorMessage, error);
    throw error?.response?.data;
  }
}
