import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/Button/Button';
import ButtonLabel from '../../../../components/Button/ButtonLabel';
import FormField from '../../../../components/FormField/FormField';
import { useUpdateUserDataContext } from '../../../../data/UpdateUserData/UpdateUserDataContext';
import { useControllerFlowStepsContext } from '../../../../hooks/ControllerFlowStepsContext';
import { useControlNavBarContext } from '../../../../hooks/ControlNavBar';
import { checkExistNumberPhone } from '../../../../services/queryController';
import { createTokenValidatePhone } from '../../../../services/tokenValidation';
import { mobilePhoneMask } from '../../../../utils/Masks';
import { SentryContextsEnum } from '../../../../utils/Sentry';

interface RegisterPhoneProps {
  previousPath?: number | null;
  nextPath: number | null;
  handleChangeStep(e: number): void;
}

export default function RegisterPhone({
  nextPath,
  handleChangeStep
}: RegisterPhoneProps) {
  const {
    register,
    getValues,
    setValue,
    errors,
    touchedFields,
    watch,
    setError,
    updatedPhone,
    clearErrors,
    sizeView
  } = useUpdateUserDataContext();
  const { showErrorMessageServer, showCooldownErrorMessage } =
    useControllerFlowStepsContext();
  const { setShowButton, setGoBackNavigate } = useControlNavBarContext();
  const [fieldValidate, setValueValidateField] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setShowButton(true);
    setValueValidateField('');
    setValue('cellPhoneNumber', '');
    clearErrors('cellPhoneNumber');
  }, []);

  useEffect(() => {
    setGoBackNavigate({
      path: `/menu-de-conta/atualizar-dados${
        sizeView[0] < 576 ? '/conta' : ''
      }`,
      param: {}
    });
  }, [sizeView]);

  // useEffect(() => {
  //   navigate('../telefone', { relative: 'path' });
  // }, [navigate]);

  window.onpopstate = () => {
    goBack();
  };

  function goBack() {
    navigate(
      `/menu-de-conta/atualizar-dados${sizeView[0] < 576 ? '/conta' : ''}`,
      {
        replace: true
      }
    );
  }

  useEffect(() => {
    const subscription = watch((data: any) => {
      setValueValidateField(data.cellPhoneNumber);
    });
    return () => subscription?.unsubscribe();
  }, [watch]);

  async function postCreateToken() {
    try {
      const formatValue = fieldValidate?.replace(/\D/g, '');
      setLoading(true);
      await createTokenValidatePhone(
        formatValue,
        updatedPhone
          ? SentryContextsEnum.CHANGE_PHONE
          : SentryContextsEnum.ADD_PHONE
      );
      nextStep();
    } catch (error: any) {
      console.log(error);
      if (error?.statusCode === 429) showCooldownErrorMessage();
      else showErrorMessageServer();
      setLoading(false);
    }
  }

  async function checkExistPhone() {
    let numberPhone = getValues('cellPhoneNumber');
    numberPhone = numberPhone?.replace(/\D/g, '')?.replace(/\s/g, '');
    setLoading(true);
    try {
      await checkExistNumberPhone(
        numberPhone,
        updatedPhone
          ? SentryContextsEnum.CHANGE_PHONE
          : SentryContextsEnum.ADD_PHONE
      );
      postCreateToken();
    } catch (error: any) {
      if (error?.statusCode === 409)
        setError('cellPhoneNumber', {
          message: 'número de celular já vinculado a uma conta'
        });
      else {
        showErrorMessageServer();
        console.log(error);
      }
      setLoading(false);
    }
  }

  function nextStep() {
    if (nextPath) {
      handleChangeStep(nextPath);
    }
  }

  return (
    <>
      <div className="flex flex-column">
        <h2 className="color-text-default">
          {updatedPhone ? 'alterar celular' : 'incluir celular'}
        </h2>
        {updatedPhone ? null : (
          <h6 className="mti-8 mbi-8 color-text-default">
            adicionar número de celular
          </h6>
        )}

        <span className={`md ${updatedPhone ? 'mt-16' : 'mt-4'}`}>
          Para validar seu número de celular é necessário digitar um código de 6
          dígitos enviado por SMS
        </span>
        <span className="md mt-8">
          O seu número de celular será utilizado para recuperar sua conta caso
          você esqueça ou perca seu e-mail
        </span>

        <div className="flex flex-column mt-16">
          <FormField
            label="número de celular"
            prefix
            labelPrefix="+55"
            iconPrefix="flagBrasil"
            placeholder="digite seu telefone celular"
            inputMode="tel"
            name="cellPhoneNumber"
            onKeyUp={mobilePhoneMask}
            register={register('cellPhoneNumber')}
            error={errors}
            touchedFields={touchedFields}
          />
          <Button
            id="add-phone"
            className="mt-8 mbi-0"
            full
            loading={loading}
            onClick={checkExistPhone}
            disabled={
              !!errors.cellPhoneNumber ||
              (fieldValidate.length && fieldValidate.length < 13) ||
              !fieldValidate.length
            }
          >
            <ButtonLabel loading={loading}>avançar</ButtonLabel>
          </Button>
          <Button
            className="mt-16 mbi-0"
            customColors="btn-white"
            full
            loading={loading}
            onClick={goBack}
          >
            <ButtonLabel>voltar</ButtonLabel>
          </Button>
        </div>
      </div>
    </>
  );
}
