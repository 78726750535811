import { createContext, useContext } from 'react';
import {
  FieldErrors,
  useForm,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createForm, FormDisableAccountValues } from './FormDisableAccount';

export interface DisableAccountContextData {
  register: UseFormRegister<FormDisableAccountValues>;
  handleSubmit: UseFormHandleSubmit<FormDisableAccountValues>;
  errors: FieldErrors<FormDisableAccountValues>;
  setError: UseFormSetError<FormDisableAccountValues>;
  touchedFields: Partial<Readonly<any>>;
  trigger: UseFormTrigger<FormDisableAccountValues>;
  watch: UseFormWatch<FormDisableAccountValues>;
  getValues: UseFormGetValues<FormDisableAccountValues>;
  setValue: UseFormSetValue<FormDisableAccountValues>;
}

export const DisableAccountContext = createContext(
  {} as DisableAccountContextData
);

const DisableAccountProvider = ({ children }: any) => {
  const formFields = createForm();
  const {
    register,
    trigger,
    setError,
    setValue,
    watch,
    handleSubmit,
    getValues,
    formState: { errors, touchedFields }
    // TODO remover o any
  } = useForm<FormDisableAccountValues | any>({
    mode: 'onChange',
    resolver: yupResolver(formFields, { abortEarly: false })
  });

  return (
    <DisableAccountContext.Provider
      value={{
        register,
        handleSubmit,
        errors,
        setError,
        touchedFields,
        trigger,
        getValues,
        watch,
        setValue
      }}
    >
      {children}
    </DisableAccountContext.Provider>
  );
};
export const useDisableAccountContext = () =>
  useContext<DisableAccountContextData>(DisableAccountContext);
export default DisableAccountProvider;
