import {
  createElement,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import Card from '../../components/Card/Card';
import Container from '../../components/Container/Container';
import Step from '../../components/Wizard/Step';
import Wizard from '../../components/Wizard/Wizard';
import {
  CTV_SUCCESS_PAGE_PATH,
  SIGNIN_SUCCESS_PAGE_COMPLETE_DATA,
  SIGNIN_SUCCESS_PAGE_VALIDATE_EMAIL
} from '../../data/Constants';
import SignInProvider, { SignInContext } from '../../data/SignIn/SignInContext';
import EmailPage from '../Email';
import SignIn from '../SignIn';
import SuccessSignIn from '../SuccessSignIn';
import ValidateEmail from '../ValidateEmail';
import { useControllerFlowStepsContext } from '../../hooks/ControllerFlowStepsContext';
import SuccessSignUp from '../SuccessSignUp';
import { useAuthContext } from '../../hooks/AuthContext';
import {
  setOriginDomainCustom,
  shouldOpenMenuAccount
} from '../../utils/GenericFunctions';
import { useNavigate } from 'react-router-dom';
import { IAccountControllerLogin } from '../../interfaces/ISocial';

export default function SignInWizard() {
  const components: any = {
    SignIn: {
      id: 'login',
      component: SignIn,
      allowSkipStep: false,
      updateData: false
    },
    EmailPage: {
      id: 'cadastro-email',
      component: EmailPage,
      allowSkipStep: false,
      updateData: false
    },
    ValidateEmail: {
      id: 'validar-email',
      component: ValidateEmail,
      allowSkipStep: true,
      updateData: false
    },
    SuccessSignIn: {
      id: 'cadastro-concluido',
      component: SuccessSignIn,
      allowSkipStep: false,
      updateData: false
    },
    SuccessSignUp: {
      id: 'cadastro-concluido',
      component: SuccessSignUp,
      updateData: false,
      allowSkipStep: false
    }
  };
  const {
    setControllerViews,
    controllerViews,
    allowSkipValidateEmail,
    ctvCode
  } = useControllerFlowStepsContext();
  const { redirectToSite, setSSORedirect, redirectURL } = useAuthContext();
  const [viewSuccess, setViewSuccess] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [defaultSteps, setDefaultSteps] = useState([
    getComponent('SignIn', false, false)
  ]);
  const navigate = useNavigate();

  useEffect(() => {
    setOriginDomainCustom();
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get('redirect')) {
      setSSORedirect(query.get('redirect') as string);
    }

    if (Object.keys(controllerViews).length) {
      controllerViewsPage(controllerViews);
    }
  }, [controllerViews]);

  function getComponent(
    key: string,
    updateData: boolean,
    allowSkipStep: boolean
  ) {
    const component = components[key];
    component['updateData'] = updateData;
    component['allowSkipStep'] = allowSkipStep;
    return component;
  }

  const handleChangeStep = useCallback((indexStep: number) => {
    setCurrentStep(indexStep);
  }, []);

  const steps = useMemo(() => defaultSteps, [defaultSteps]);

  function controllerViewsPage(data: IAccountControllerLogin) {
    const updateSteps = [];
    if (data.mergeAccount) {
      redirect();
      setControllerViews({});
    } else {
      if (data.emailValidated === false) {
        updateSteps.push(
          getComponent('ValidateEmail', false, allowSkipValidateEmail)
        );
        setViewSuccess(SIGNIN_SUCCESS_PAGE_VALIDATE_EMAIL);
      } else {
        if (data.hasEmail === false) {
          updateSteps.push(getComponent('EmailPage', false, true));
        }

        setViewSuccess(SIGNIN_SUCCESS_PAGE_COMPLETE_DATA);
      }

      if (updateSteps.length) {
        updateSteps.push(getComponent('SuccessSignIn', false, false));
        setDefaultSteps(updateSteps);
      } else {
        redirect();
      }
    }
  }

  function redirect() {
    if (ctvCode) return navigate(CTV_SUCCESS_PAGE_PATH);
    if (shouldOpenMenuAccount(redirectURL)) return navigate('/menu-de-conta');
    redirectToSite();
  }

  const buildSteps = useCallback(() => {
    const lastIndex = steps.length - 1;
    return steps.map((step, index) => {
      const component = createElement(step.component, {
        previousPath: index > 0 ? index - 1 : null,
        nextPath: index < lastIndex ? index + 1 : null,
        allowSkipStep: step.allowSkipStep,
        handleChangeStep,
        context: SignInContext,
        updateData: step.updateData,
        viewSuccess: viewSuccess
      });
      return (
        <Step id={index} currentStep={currentStep} key={index}>
          {component}
        </Step>
      );
    });
  }, [currentStep, handleChangeStep, steps]);

  return (
    <SignInProvider>
      <Container className="signin">
        <Card width="400px" minHeight="550px" center>
          <form className="width-full" onSubmit={(e) => e.preventDefault()}>
            <Wizard>{buildSteps()}</Wizard>
          </form>
        </Card>
      </Container>
    </SignInProvider>
  );
}
