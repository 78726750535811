import { Navigate } from 'react-router-dom';
import { useControllerSocialMediaContext } from '../../hooks/ControllerSocialMedia';
import { useEffect } from 'react';
import RedirectLoader from './RedirectLoader';
import { PROVIDER_SOCIAL } from '../../data/Constants';

let isLoading = false;

export default function AppleRedirect() {
  const { sendToken } = useControllerSocialMediaContext();

  const query = new URLSearchParams(location.search);
  // const code = query.get('code');
  const idToken = query.get('id_token');
  const userQuery = query.get('user');

  useEffect(() => {
    if (!isLoading && idToken) {
      console.log('Autenticando com Apple');
      isLoading = true;

      let user;
      try {
        user = userQuery ? JSON.parse(userQuery) : undefined;
      } catch (e) {
        console.error(e);
      }

      sendToken(
        idToken,
        PROVIDER_SOCIAL.APPLE,
        user && user?.name && user?.name?.firstName && user?.name?.lastName
          ? user?.name?.firstName.trim() + ' ' + user?.name?.lastName.trim()
          : undefined
      );
    }
  }, []);

  if (!idToken) return <Navigate to={'/'} />;

  return <RedirectLoader />;
}
