export const POLICY = `
    <p>Seja bem-vindo à política de privacidade do SBT.</p>
    <p>O SBT preocupa-se com sua privacidade e acreditamos que você deve estar no controle sobre seus dados pessoais.</p>
    <p>Por isso, preparamos essa Política de Privacidade para ajudar você a entender melhor como usamos seus dados pessoais
        em nossas páginas, na utilização de nossos serviços, sites, aplicativos, softwares, formulários físicos e demais
        plataformas que possam conter seus dados pessoais.</p>
    <p>1. Para que serve esse documento</p>
    <p>Criamos essa Política de Privacidade para ajudar você a entender quais são as práticas adotadas pelo SBT ao realizar
        o tratamento de seus dados pessoais e o motivo pelo qual seus dados são solicitados ou coletados, demonstrando o
        nosso compromisso com a boa-fé, segurança, privacidade e transparência.</p>
    <p>Esta política é aplicável à todas as pessoas que acessarem as Plataformas Digitais e poderá ser aplicável a outras
        formas de coleta de dados pelo SBT, que permitam qualquer prestação de serviços ou fornecimento de produtos ou
        conteúdo.</p>
    <p>Ao utilizar as Plataformas Digitais nós disponibilizamos o acesso a esta política onde solicitamos a informação você
        que leu, compreendeu e aceita este documento, além de concordar em ficar vinculado por ele. Caso tenha dúvidas sobre
        o entendimento a respeito de qualquer disposição desta Política de Privacidade, entre em contato com o DPO
        (encarregado dos dados) por meio do canal oficial disponível somente para atender os titulares dos dados pessoais,
        aqui mencionado no final desta Política. Se você não concordar (ou não puder cumprir) com este documento, você não
        poderá usar as Plataformas Digitais do SBT ou demais serviços que façam uso de seus dados pessoais conforme as
        diretrizes desta política.</p>
    <p>Alguns serviços oferecidos pelo SBT podem estar sujeitos a termos e condições adicionais especificados pelo SBT ou
        terceiros periodicamente, os quais serão incorporados nesta desta Política de Privacidade para os quais você também
        poderá ter de dar seu consentimento.</p>
    <p>2. Sobre seus dados pessoais</p>
    <p>Neste item vamos falar um pouco sobre seus dados pessoais no SBT, considerando todo o ciclo de tratamento desde a
        coleta até a eliminação dos dados.</p>
    <p>2.1. Como coletamos seus dados pessoais</p>
    <p>Os seus dados pessoais poderão ser coletados ao realizar o seu cadastro nas Plataformas Digitais, acessa quaisquer de
        nossos produtos ou serviços ou participa de eventos, campanhas e promoções conforme abaixo:</p>
    <p>Durante a navegação de nossos sites através de Cookies;</p>
    <p>Compartilhados através de terceiros, quando você autoriza em plataformas de terceiros o compartilhamento de seus
        dados pessoais;</p>
    <p>Ao efetuar a instalação de algum software ou aplicação desenvolvida pelo próprio SBT ou de empresas parceiras, neste
        momento você será consultado sobre a permissão de acesso conforme o dispositivo utilizado;</p>
    <p>Eventos organizados ou com a participação do SBT;</p>
    <p>Com a aquisição de algum produto ou serviço oferecido pelo SBT ou empresas do Grupo;</p>
    <p>Inscrições em programas ou base de elencos.</p>
    <p>Importante: No momento da coleta o SBT disponibilizará o acesso a esta política para leitura e respectivo aceite
        (exceto quando a coleta ocorrer em plataforma de terceiros).</p>
    <p>2.2. Quais dados pessoais coletamos de você</p>
    <p>Dados pessoais são todos aqueles que possam identificá-lo, com isso podem ser dados cadastrais (como nome, e-mail,
        endereço), assim como dados de registro de navegação (como IP de dispositivos, histórico de navegação e cookies), o
        mínimo necessário para oferecer a melhor experiência ou para ofertar um determinado produto ou serviço requerido.
    </p>
    <p>2.3. Vamos detalhar um pouco os tipos dos dados?</p>
    <p>Dados cadastrais. Poderão ser utilizados pelo SBT diversos dados pessoais, os quais poderão incluir, mas não se
        limitam a:</p>
    <p>Dados cadastrais, tais como: nome, documentos de identificação, nacionalidade, endereço, data de nascimento,
        filiação, gênero, entre outros.</p>
    <p>Dados de contato tais como: telefone, e-mail e endereço.</p>
    <p>Dados sobre sua atividade, trabalho, profissão.</p>
    <p>Dados sobre sua renda.</p>
    <p>Dados coletados de terceiros, incluindo os já acima mencionados, suas fotografias e demais documentos de
        identificação.</p>
    <p>Dados de contratações de qualquer serviço ou produto realizados por você nas Plataformas Digitais.</p>
    <p>Dados de navegação. Poderão ser utilizados pelo SBT os seguintes dados de navegação, os quais poderão incluir, mas
        não se limitam a:</p>
    <p>Endereço de IP do dispositivo utilizado por você.</p>
    <p>Interações realizadas e uso de perfil.</p>
    <p>Dados técnicos, como informações de URL, conexão de rede, do provedor, do dispositivo.</p>
    <p>Histórico de navegação e interesses.</p>
    <p>Atributos de dispositivo, como ID, sistema operacional, navegador e modelo.</p>
    <p>Acesso a eventuais listas de contatos caso exista a ativação desta funcionalidade.</p>
    <p>Dados como cookies, pixels tags, beacons e local shared objects.</p>
    <p>Dados sobre as características dos dispositivos de acesso.</p>
    <p>Dados do navegador, incluindo IP (com data e hora) e origem do IP.</p>
    <p>Dados e informações sobre cliques, páginas acessadas, páginas seguintes acessadas após a saída das páginas
        anteriores, termos de buscas digitados em sites e nas Plataformas Digitais.</p>
    <p>2.4. Para que usamos seus dados pessoais</p>
    <p>Usamos seus dados pessoais para fornecer os nossos serviços, dentro e fora das Plataformas Digitais, da melhor forma
        possível, respeitando, sempre suas preferências de privacidade e a coleta mínima necessária. Podemos fazer uso para
        fins publicitários, de segurança e de personalização dos serviços.</p>
    <p>2.4.1. Finalidades que estão relacionadas diretamente ao seu interesse</p>
    <p>Fins de nossos serviços. O SBT utiliza os seus dados para o estabelecimento de vínculo contratual ou ainda para
        gestão, administração, prestação, ampliação e melhoramento dos produtos, serviços, bem como das Plataformas Digitais
        adequando-as às suas preferências.</p>
    <p>Fins publicitários do SBT e Grupo. Os dados pessoais e demais informações coletadas também poderão ser utilizadas
        para fins publicitários do SBT ou demais empresas do Grupo, como envio de informações de marcas, produtos, promoções
        e descontos, para a divulgação de eventos ou para a realização de pesquisas, sempre respeitando a transparência com
        o titular dos dados.</p>
    <p>Fins de segurança. Para operar nossos serviços, usamos alguns de seus dados, em especial dados de registro e de
        navegação, para assegurar seu desempenho seguro, confiável e robusto, bem como para garantir a segurança das contas
        e a devida autenticação.</p>
    <p>2.4.2. Finalidades que podemos solicitar o consentimento mesmo que atendam o seu interesse</p>
    <p>Fins publicitários de terceiros. Os dados pessoais e demais informações coletadas também poderão ser utilizadas para
        fins publicitários de terceiros ou empresas parceiras, como envio de informações de marcas, produtos, promoções e
        descontos, para a divulgação de eventos ou para a realização de pesquisas, sempre respeitando a transparência com o
        titular dos dados.</p>
    <p>Fins de personalização. Para personalizar nossos serviços, poderemos associar você a informações fornecidas por você
        ou por terceiros, por meio de inferências sobre temas sobre os quais você pode estar interessado e da customização
        do conteúdo que mostramos em nossas Plataformas Digitais, inclusive em anúncios.</p>
    <p>Demais finalidades. Os dados pessoais obtidos pelo SBT poderão também incluir as seguintes finalidades:</p>
    <p>Atendimento de suas solicitações e/ou esclarecimento de dúvidas.</p>
    <p>Contato por telefone, e-mail, SMS, aplicativo de mensagens, inclusive por meio de notificações ou push.</p>
    <p>Envio de comunicações em geral, incluindo atualizações desta Política de Privacidade bem como de demais termos
        aplicáveis.</p>
    <p>Exibição de publicidade nas Plataformas Digitais, redes sociais, inclusive em sites e plataformas de parceiros, assim
        como envio de ofertas, informações, novidades, atualizações de produtos, serviços, conteúdos, funcionalidades,
        notícias, eventos relevantes que possam ser de seu interesse e para manutenção de relacionamento com você.</p>
    <p>Prevenção e resolução de problemas técnicos e de segurança.</p>
    <p>Prevenção e combate a fraudes e demais atos ilícitos.</p>
    <p>Colaboração ou cumprimento de ordem judicial de autoridade competente ou órgão fiscalizador, assim como cumprimento
        de obrigação legal ou regulatória.</p>
    <p>Melhoria dos sistemas, produtos e serviços.</p>
    <p>Recomendações e operacionalização de novos serviços, produtos ou funcionalidades de aplicativos, softwares,
        plataformas, sites, inclusive de serviços oferecidos por parceiros.</p>
    <p>Geração de estatísticas, estudos, pesquisas e levantamentos pertinentes às atividades e comportamento no uso das
        Plataformas Digitais, produtos ou serviços.</p>
    <p>Dados de geolocalização, quando possível.</p>
    <p>2.5. Compartilhamento dos seus dados pessoais</p>
    <p>Seus dados pessoais e informações também poderão ser compartilhados com prestadores de serviços, sempre sujeito às
        obrigações consistentes com esta Política de Privacidade, tais como empresas provedoras de infraestrutura
        tecnológica e operacional, empresas de atendimento ao consumidor, empresas de comunicação de serviços, empresas
        intermediadoras de pagamento e empresas e provedores de serviço de armazenamento de informações e demais parceiros
        necessários para a completa atividade do SBT. Esse compartilhamento é essencial para que você possa melhor utilizar
        nossos serviços.</p>
    <p>Anúncios</p>
    <p>Ao aceitar os termos disponibilizados em nossas Plataformas Digitais, você concorda que o SBT e seus parceiros podem
        utilizar parte de seus dados pessoais para disponibilizar anúncios que consideramos relevantes para você e seus
        interesses nas Plataformas Digitas ou em conexão com a exibição de conteúdo. Para o fornecimento de anúncios mais
        customizados e eficazes, nossos parceiros podem compartilhar conosco algumas de suas informações pessoais, em
        especial dados de navegação, localização e interesse, permitindo que os combinemos com dados pessoais já coletados.
        A receita oriunda de publicidade permite ao SBT continuadamente melhore seus serviços.</p>
    <p>Outros compartilhamentos.</p>
    <p>Poderemos usar e compartilhar dados pessoais ou outros dados de segurança se acreditarmos que são razoavelmente
        necessários para:</p>
    <p>Cumprimento de obrigação legal ou regulatória, incluindo ordem judicial ou requerimento de autoridades
        administrativas que detenham competência legal para tal requisição.</p>
    <p>Exercício regular do direito do SBT, incluindo proteção dos interesses do SBT em qualquer tipo de conflito, incluindo
        ações judiciais.</p>
    <p>Proteger a segurança de qualquer pessoa.</p>
    <p>Proteger a segurança ou integridade de nossa plataforma.</p>
    <p>Proteger você ou terceiros de fraude.</p>
    <p>Proteger direitos ou nossa propriedade ou de terceiros que usam nossos serviços.</p>
    <p>Casos de transações e alterações societárias envolvendo o SBT, hipótese em que a transferência das informações será
        necessária para a continuidade dos serviços e utilização das Plataformas Digitais.</p>
    <p>Envio de notificações não obrigatórias por e-mail, aplicativos de mensagens, SMS e notificação push.</p>
    <p>Cumprir com o legítimo interesse do SBT e das empresas que integrem o grupo empresarial do SBT para os mesmos fins
        indicados na presente Política de Privacidade.</p>
    <p>Em caso de exceções não previstas nos itens acima, o SBT aplicará o termo de consentimento.</p>
    <p>Consultar a veracidade dos dados através de um serviço de birô.</p>
    <p>2.6. Coleta e uso de dados de menores de idade</p>
    <p>Para realizar o cadastro em nossas Plataformas Digitais, o titular do dado precisa ter no mínimo 18 anos de idade.
        Caso o SBT oferecer algum produto ou serviço destinado aos menores de idade, será obrigatório o consentimento formal
        de um dos pais ou responsável legal.</p>
    <p>Idades mínimas</p>
    <p>Para utilizar nossos serviços, você deve ter, no mínimo, 13 anos de idade, assim como, para se cadastrar nas
        Plataformas Digitais, você precisa ter, no mínimo, 18 anos de idade.</p>
    <p>Dados coletados</p>
    <p>Para garantir a máxima proteção à criança ou ao adolescente, somente serão solicitados os dados indispensáveis para a
        realização da inscrição específica, tais como nome do menor, idade, foto, entre outros e consentimento formal de um
        dos pais ou responsável legal. Ao realizar o cadastro de criança ou adolescente, você assume a veracidade dos dados
        fornecidos, bem como a condição de responsável legal do menor.</p>
    <p>2.7. Retenção e exclusão de seus dados pessoais</p>
    <p>Excluiremos seus dados pessoais quando não mais forem necessários para a finalidade a eles estipuladas, quando a
        política de retenção assim o determinar ou quando você ou uma autoridade requerer. Lembre-se que, ainda assim,
        poderemos manter parte de seus dados, caso seja legalmente requerido ou permitido.</p>
    <p>Exclusão dos dados. Enquanto você mantiver relacionamento com o SBT, bem como durante o uso das Plataformas Digitais
        e de nossos de serviços, o SBT armazenará seus dados em ambientes seguros e controlados, mas poderão ser excluídos:
    </p>
    <p>Quando os dados deixarem de ser necessários ou pertinentes à finalidade específica pela qual foram disponibilizados.
    </p>
    <p>Quando terminar o período do tratamento.</p>
    <p>Quando você solicitar que o SBT bloqueie ou elimine esses dados.</p>
    <p>Retenção dos dados. Mesmo em caso de requisição de exclusão de dados ou após o término do relacionamento com você,
        será respeitado o prazo de armazenamento mínimo de informações de usuários de aplicações de Internet, determinado
        pela legislação brasileira, resguardados os requisitos de tratamento previstos quando anonimizados os dados. O SBT
        poderá armazenar seus dados por um período adicional de tempo para fins de auditoria, para cumprimento de obrigações
        legais ou regulatórias, para preservar os direitos do SBT em eventuais discussões judiciais e para observar as bases
        legais que justifique a retenção dos dados, como, por exemplo, para cumprir eventual solicitação da Autoridade
        Nacional de Proteção de Dados (ANPD).</p>
    <p>Exclusão definitiva de conta. É necessário que você esteja ciente de que a exclusão das informações essenciais para
        gestão de sua conta de acesso às Plataformas Digitais implicará o término de seu cadastro, com consequente
        cancelamento de seu acesso e potencial perda permanente dos dados até então coletados.</p>
    <p>3. Vamos falar um pouco sobre alguns produtos e serviços</p>
    <p>3.1. Site do SBT</p>
    <p>Durante a utilização de site SBT o Usuário estará sujeito a fornecer dados pessoais para customização de conteúdo,
        publicidade personalizada, cadastro para ficar por dentro de campanhas e novidades envolvendo a emissora, inscrições
        em Quadros ou Programas do SBT e campanhas envolvendo empresas parceiras.</p>
    <p>3.1.1. SBOT - Assistente Virtual</p>
    <p>Recurso de voz no SBOT. É um recurso que permite que você insira texto utilizando a sua voz. Para esta função
        utilizamos o processamento Speech-to-Text do Google, onde ao utilizar esta função o usuário estará sujeito também as
        políticas da empresa informadas abaixo:</p>
    <p><a href="https://cloud.google.com/terms/cloud-privacy-notice"
            target="_blank">https://cloud.google.com/terms/cloud-privacy-notice</a></p>
    <p><a href="https://cloud.google.com/speech-to-text/docs/data-logging"
            target="_blank">https://cloud.google.com/speech-to-text/docs/data-logging</a></p>
    <p>O SBT não processa ou retém para outra finalidade os áudios utilizados no Assistente Virtual.</p>
    <p>3.2. SBT Vídeos</p>
    <p>A utilização do SBT Vídeos possibilitará o cadastro do Usuário para que possa receber informações personalizadas,
        criar listas de conteúdos para assistir, gerenciar perfis, além de outras funcionalidades para melhorar a sua
        experiência com o SBT Vídeos. O cadastro será necessário e se dará mediante prévio consentimento de forma livre,
        expressa, individual, clara, específica e legítima do Usuário, manifestando, de maneira precisa, o seu interesse e
        sua concordância em fornecer seus dados pessoais e informações que lhe forem solicitadas, bem como manifestando a
        sua concordância com relação ao conteúdo da presente Política de Privacidade e dos Termos de Uso deste produto do
        SBT.</p>
    <p>O SBT Vídeos é uma plataforma direcionada a todos os públicos, de todas as idades, porém o Usuário que realizar o
        cadastro necessita ter no mínimo 18 (dezoito) anos de idade. Ao aceitar os Termos de Uso e a Política de
        Privacidade, o Usuário declara ter no mínimo 18 (dezoito) anos de idade completos, bem como ser plenamente capaz de
        consentir com todas as disposições, condições, obrigações e afirmações presentes nestes termos.</p>
    <p>Qualquer Usuário poderá, em qualquer tempo, revogar o consentimento fornecido e deletar sua conta quando desejar.
        Contudo, a revogação do consentimento implicará no fim da personalização disponibilizada pelo APLICATIVO e o SITE.
        Também serão conservados os dados necessários a cumprimentos de obrigações legais ou regulatórias. A revogação do
        consentimento referente a qualquer informação da emissora, campanhas promocionais do SBT, Empresas do Grupo ou de
        parceiros o Usuário deverá utilizar os canais informados no item 9.1 desta Política de Privacidade. No que se refere
        a exclusão de seus dados ou conta o SBT Vídeos proporciona dentro da própria plataforma.</p>
    <p>3.2.1. Tratamento de dados no SBT Vídeos</p>
    <p>O Usuário poderá criar diversos perfis e no momento de criação, será solicitado nome e data de nascimento.</p>
    <p>Por meio do aceite desta Política o Usuário confirma e autoriza o envio de notificações automáticas sobre conteúdos
        considerados de interesse pelo SBT (“push notification”) bem como de eventos a serem realizados. O aceite poderá ser
        solicitado novamente durante o uso do SBT Vídeos no dispositivo móvel, conforme regem as políticas de privacidade do
        ANDROID e IOS.</p>
    <p>3.3 Players de Vídeos</p>
    <p>Para oferecer os serviços do SBT AO VIVO e SBT Vídeos nós usamos o player de vídeo incorporado do YouTube (“YouTube
        Player”). O YouTube pode coletar dados de uso PRIVILEGIADOS & CONFIDENCIAIS quando você usa, interage ou se envolve
        com o YouTube Player através dos nossos Serviços. Você pode saber mais sobre a Política de Privacidade do Google em
        relação ao YouTube Player aqui: <a
            href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>.</p>
    <p>4. Como mantemos as suas informações e seus dados pessoais seguros</p>
    <p>Estamos comprometidos com a adoção de políticas e normas de boas práticas relativas à proteção dos dados pessoais.
        Implementamos as medidas técnicas e organizacionais pertinentes, considerando a tecnologia existente, para a
        segurança de dados, ainda que não possamos garantir que estejamos imunes a atos ilícitos de terceiros.</p>
    <p>Segurança de sua informação.</p>
    <p>Os sistemas e procedimentos do SBT são estruturados de forma a atender aos requisitos de segurança, aos padrões de
        boas práticas e aos princípios gerais previstos na legislação aplicável e demais normas regulamentares. O SBT
        armazenará suas informações e dados pessoais coletados em suas Plataformas Digitais em servidores próprios ou por
        nós contratados, utilizando os meios razoáveis de mercado e legalmente requeridos para preservar a privacidade sua
        privacidade, tais como:</p>
    <p>Métodos padrões e de mercado para criptografar e anonimizar os dados.</p>
    <p>Proteção contra acesso não autorizado a seus sistemas.</p>
    <p>Acesso restrito às pessoas previamente autorizadas ao local onde são armazenados os dados e as informações coletadas.
    </p>
    <p>Comprometimento e sigilo absoluto pelas pessoas que tiverem acesso aos dados e informações.</p>
    <p>Manutenção de inventário indicando momento, duração, identidade do funcionário, ou do responsável pelo acesso com
        base nos registros de conexão e de acesso a aplicações.</p>
    <p>Importante. É importante que você saiba que nenhum site é totalmente seguro. O SBT adota as melhores práticas de
        segurança da informação, porém incentivamos você a tomar as medidas apropriadas para reforçar esta proteção, como,
        por exemplo, mantendo confidenciais todos os nomes de usuário e senhas bem como não transferindo os seus dados de
        acesso a quaisquer terceiros.</p>
    <p>5. Seus direitos como titular dos dados pessoais</p>
    <p>Você poderá exercer seus direitos de titular de dado pessoal, tais como requerer acesso aos dados pessoais que
        coletamos, assim como requerendo retificação, atualização, portabilidade, revogação de consentimento e eliminação de
        seus dados pessoais.</p>
    <p>Direitos de titular. Enquanto titular de dados pessoais, você poderá exercer uma série de direitos.
        Disponibilizaremos mecanismos para que você possa entender de forma clara e transparente como poderá exercer seus
        direitos. O SBT empreenderá todos os esforços para atender tais pedidos no menor espaço de tempo possível.</p>
    <p>Acesso a dados. Você poderá solicitar através do canal exclusivo para esta finalidade, a qualquer momento, que o SBT
        lhe informe e confirme a existência de tratamento de dados em nossas Plataformas Digitais ou demais bases sob a
        responsabilidade da emissora, bem como forneça os dados que possuímos de você.</p>
    <p>Retificação e atualização de dados. Se você identificar que os seus dados pessoais necessitam de correções ou se
        encontrarem incompletos, inexatos ou desatualizados, você poderá, caso não haja possibilidade de fazê-lo diretamente
        em nossas Plataformas Digitais, encaminhar solicitação para o canal dedicado ao titular dos dados. Lembre-se que
        você pode ter de comprovar, inclusive por meio de documento, a veracidade desses dados.</p>
    <p>Demais direitos. O SBT respeita e garante a você a possibilidade de apresentação de solicitações baseadas nos
        seguintes direitos:</p>
    <p>Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade.</p>
    <p>Portabilidade de seus dados a outro fornecedor de serviço ou produto.</p>
    <p>Eliminação dos dados tratados com o seu consentimento.</p>
    <p>Informação sobre a possibilidade de não fornecer o seu consentimento, bem como de ser informado sobre as
        consequências em caso de negativa.</p>
    <p>6. Coleta e revogação do consentimento</p>
    <p>Poderemos pedir seu consentimento para alguns dos usos desses dados pessoais. Você tem o direito de não dar seu
        consentimento, assim como de revogá-lo, mas o SBT poderá ainda fazer uso de parte desses dados pessoais, nas
        hipóteses previstas em lei.</p>
    <p>Obtenção de consentimento. Caso seu consentimento seja necessário para uso dos seus dados pessoais, de acordo com o
        disposto na lei aplicável, ele será coletado eletronicamente, momento em que serão informados os procedimentos e
        finalidades. Você possui o direito de não oferecer o seu consentimento ou de solicitar maiores esclarecimentos
        acerca desse consentimento.</p>
    <p>Revogação do consentimento. Você poderá solicitar a revogação do consentimento que concedeu. Esta revogação poderá
        ocasionar a impossibilidade de uso ou acesso de funcionalidades dos sistemas e serviços do SBT. É importante que
        você esteja ciente de que a revogação do seu consentimento não significa que seus dados pessoais serão eliminados,
        tendo em vista a possiblidade de conservação autorizada dos dados para as finalidades dispostas na lei aplicável.
    </p>
    <p>7. Informações sobre legislação</p>
    <p>A legislação brasileira é aplicável a esta Política de Privacidade, em especial a LGPD, e o foro é o da comarca da
        cidade de Osasco/SP.</p>
    <p>Nulidade ou incompletude de cláusulas. Qualquer cláusula ou condição destes Termos que venha a ser reputada nula ou
        ineficaz por qualquer juízo ou tribunal, não afetará a validade das demais disposições, as quais permanecerão
        plenamente válidas e vinculantes. Nossa falha em exigir quaisquer direitos ou disposições dos presentes Termos não
        constituirá renúncia, podendo exercer regularmente o seu direito, dentro dos prazos legais.</p>
    <p>Legislação aplicável e foro. Esta Política de Privacidade é regida, interpretada e executada de acordo com as Leis da
        República Federativa do Brasil, especialmente a Lei nº 13.709/2018, sendo competente o foro de Osasco para dirimir
        qualquer controvérsia decorrente desta Política de Privacidade.</p>
    <p>7.1. Pelo que o SBT não se responsabiliza</p>
    <p>O SBT será responsável apenas pelos danos diretos oriundos de nossas obrigações. Você continua responsável pelos
        dados que forneceu ao SBT.</p>
    <p>Responsabilidade do SBT. O SBT será responsável apenas pelos danos diretos que comprovadamente vier a ocasionar, não
        assumindo responsabilidade:</p>
    <p>Por erros ou inconsistências na transmissão de dados que você apresentou ou que automaticamente forem coletados,
        incluindo dados imprecisos, inverídicos, incompletos.</p>
    <p>Por utilização das Plataformas Digitais em desacordo com o disposto nesta Política de Privacidade.</p>
    <p>Por qualquer dano, ou prejuízo decorrente de utilização não autorizada ou indevida de seus dados.</p>
    <p>Pela violação de direitos de terceiros ou ainda pela utilização não autorizada de dados de terceiros.</p>
    <p>8. Entre em contato conosco</p>
    <p>8.1. Canal de Privacidade</p>
    <p>Se você precisar sanar dúvidas sobre como seus dados pessoais estão sendo tratados no SBT ou queira formalizar alguma
        solicitação prevista na Lei Geral de Proteção de Dados, você deverá entrar em contato com a nossa Encarregada pelo
        tratamento dos dados pessoais do SBT.</p>
    <p>A Encarregada pelo tratamento de dados pessoais no SBT acompanhará todas as solicitações direcionadas ao canal de
        privacidade, assim mantendo a interação entre o titular dos dados pessoais e a emissora.</p>
    <p>Encarregada (DPO): Luciane Shinohara</p>
    <p>E-mail: dpo@sbt.com.br</p>
    <p>8.2. SAC</p>
    <p>Para tirar demais dúvidas sobre produtos ou serviços do SBT, você deverá entrar em contato conosco por meio do e-mail
        ola@sbtcomvc.com.br.</p>
    <p>Última Atualização em 11.09.2024</p>
`;
