import { ErrorMessages } from '../data/ErrorMessages';
import { DELETE, PUT } from './api';

const ACCOUNT_SERVICE = process.env.REACT_APP_BASE_URL_ACCOUNT_SERVICE;

export async function updateUsersActive(active: boolean): Promise<void> {
  await PUT(`${ACCOUNT_SERVICE}/api/v1/users/active`, {
    active
  });
}

export async function unlinkSocialAccount(
  email: string,
  provider: string
): Promise<void> {
  const response = await DELETE(
    `${ACCOUNT_SERVICE}/api/v1/users/credentials/provider`,
    {
      data: { provider }
    },
    ErrorMessages.DELETE_SOCIAL_MERGE
  );
  return response.data;
}
