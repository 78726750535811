import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import './FormField.scss';

interface FormFieldCodeSimpleProps {
  setState: React.Dispatch<
    React.SetStateAction<{
      value: string;
      isValid: boolean;
    }>
  >;
  fieldValidate: {
    value: string;
    isValid: boolean;
  };
  errorToken: boolean;
}

export default function FormFieldCodeSimple({
  fieldValidate,
  errorToken,
  setState
}: FormFieldCodeSimpleProps) {
  const initialValue = {
    number1: '',
    number2: '',
    number3: '',
    number4: '',
    number5: '',
    number6: '',
    number7: '',
    number8: ''
  };
  const [numbers, setNumbers] = useState(initialValue);
  const refBox1 = useRef<HTMLInputElement>(null);
  const refBox2 = useRef<HTMLInputElement>(null);
  const refBox3 = useRef<HTMLInputElement>(null);
  const refBox4 = useRef<HTMLInputElement>(null);
  const refBox5 = useRef<HTMLInputElement>(null);
  const refBox6 = useRef<HTMLInputElement>(null);
  const refBox7 = useRef<HTMLInputElement>(null);
  const refBox8 = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (refBox1?.current) {
        refBox1?.current?.focus();
        refBox1?.current?.click(); // Adicionando um clique pode ajudar em alguns dispositivos
      }
    }, 150); // Um pequeno atraso, ajustável conforme necessário

    return () => clearTimeout(timer); // Limpar o timeout ao desmontar
  }, []);

  const checkLength = useCallback(
    (
      e: React.FormEvent<HTMLInputElement | null>,
      inputRef: any,
      previosRef?: RefObject<HTMLInputElement | null>,
      nextRef?: RefObject<HTMLInputElement | null>
    ) => {
      e.currentTarget.maxLength = 1;
      let value = e.currentTarget.value;
      value = value.replace(/\D/g, '');
      e.currentTarget.parentElement?.classList.remove(
        'border-filled',
        'border-error'
      );
      e.currentTarget.parentElement?.classList.add(
        value ? 'border-filled' : 'border-error'
      );

      setNumbers((state) => ({ ...state, [inputRef]: value }));
      e.currentTarget.value = value;
      if (e.currentTarget.value.length === 1 && nextRef) {
        nextRef.current?.focus();
      }
    },
    []
  );

  useEffect(() => {
    setErrorToken();
  }, [errorToken]);

  function setErrorToken() {
    if (fieldValidate.value && fieldValidate.isValid) {
      const fields: any = document.querySelectorAll('.form-field-blocks__box');
      fields?.forEach((field: any) => {
        field.classList.remove('border-filled');
        field.classList.add('border-error');
      });
    }
  }

  function checkInputValids() {
    const numberKeys = Object.keys(numbers).length;
    let countValidInput = 0;
    let token = '';
    for (const field of Object.entries(numbers)) {
      if (field[1]) {
        token = token + field[1];
        countValidInput++;
      }
    }

    setState({ value: token, isValid: countValidInput == numberKeys });
  }

  useEffect(() => {
    checkInputValids();
  }, [numbers]);

  function maxOneNumber(e: React.FormEvent<HTMLInputElement>) {
    e.currentTarget.maxLength = 1;
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, '');

    e.currentTarget.value = value;

    return e;
  }

  const handleKeyDown = (
    e: React.FormEvent<HTMLInputElement> | any,
    previosRef?: RefObject<HTMLInputElement> | any
  ) => {
    const { value } = e.target;
    if (e.keyCode === 8 && value === '') {
      if (previosRef !== null) {
        previosRef.current.focus();
      }
    }
  };

  return (
    <>
      <div className="form-field-blocks form-field-blocks-8-digits">
        <div className="form-field-blocks__box">
          <input
            ref={refBox1}
            type="tel"
            value={numbers.number1}
            onKeyUp={maxOneNumber}
            onInput={(e) => checkLength(e, 'number1', undefined, refBox2)}
            onKeyDown={(e) => handleKeyDown(e, null)}
            inputMode="numeric"
          />
        </div>
        <div className="form-field-blocks__box">
          <input
            ref={refBox2}
            type="tel"
            value={numbers.number2}
            onKeyUp={maxOneNumber}
            onInput={(e) => checkLength(e, 'number2', refBox1, refBox3)}
            onKeyDown={(e) => handleKeyDown(e, refBox1)}
            inputMode="numeric"
          />
        </div>
        <div className="form-field-blocks__box">
          <input
            ref={refBox3}
            type="tel"
            value={numbers.number3}
            onKeyUp={maxOneNumber}
            onInput={(e) => checkLength(e, 'number3', refBox2, refBox4)}
            onKeyDown={(e) => handleKeyDown(e, refBox2)}
            inputMode="numeric"
          />
        </div>
        <div className="form-field-blocks__box">
          <input
            ref={refBox4}
            type="tel"
            value={numbers.number4}
            onKeyUp={maxOneNumber}
            onInput={(e) => checkLength(e, 'number4', refBox3, refBox5)}
            onKeyDown={(e) => handleKeyDown(e, refBox3)}
            inputMode="numeric"
          />
        </div>

        <div className="form-field-blocks__separator">-</div>

        <div className="form-field-blocks__box">
          <input
            ref={refBox5}
            type="tel"
            value={numbers.number5}
            onKeyUp={maxOneNumber}
            onInput={(e) => checkLength(e, 'number5', refBox4, refBox6)}
            onKeyDown={(e) => handleKeyDown(e, refBox4)}
            inputMode="numeric"
          />
        </div>
        <div className="form-field-blocks__box">
          <input
            ref={refBox6}
            type="tel"
            value={numbers.number6}
            onKeyUp={maxOneNumber}
            onInput={(e) => checkLength(e, 'number6', refBox5, refBox7)}
            onKeyDown={(e) => handleKeyDown(e, refBox5)}
            inputMode="numeric"
          />
        </div>
        <div className="form-field-blocks__box">
          <input
            ref={refBox7}
            type="tel"
            value={numbers.number7}
            onKeyUp={maxOneNumber}
            onInput={(e) => checkLength(e, 'number7', refBox6, refBox8)}
            onKeyDown={(e) => handleKeyDown(e, refBox6)}
            inputMode="numeric"
          />
        </div>
        <div className="form-field-blocks__box">
          <input
            ref={refBox8}
            type="tel"
            value={numbers.number8}
            onKeyUp={maxOneNumber}
            onInput={(e) => checkLength(e, 'number8', refBox7)}
            onKeyDown={(e) => handleKeyDown(e, refBox7)}
            inputMode="numeric"
          />
        </div>
      </div>
      {/* {errorToken ? (
        <span className="sm error-token">código incorreto ou expirado.</span>
      ) : null} */}
    </>
  );
}
