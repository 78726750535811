import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AlertBox, { AlertBoxHandles } from '../../components/AlertBox/AlertBox';
import Button from '../../components/Button/Button';
import ButtonLabel from '../../components/Button/ButtonLabel';
import FormField from '../../components/FormField/FormField';
import LogoText from '../../components/LogoText/LogoText';
import { useControllerFlowStepsContext } from '../../hooks/ControllerFlowStepsContext';
import { checkEmailExist } from '../../services/queryController';

import { createTokenValidateEmail } from '../../services/tokenValidation';
import CheckToken from '../CheckToken';

export interface EmailPageProps {
  allowSkipStep?: boolean;
  updateData?: boolean;
  previousPath?: number | null;
  nextPath?: number | null;
  handleChangeStep(e: number): void;
  context: any;
}

export default function EmailPage({
  nextPath,
  previousPath,
  handleChangeStep,
  context
}: EmailPageProps) {
  const { socialWithoutEmail, showErrorMessageServer } =
    useControllerFlowStepsContext();
  const {
    register,
    errors,
    watch,
    touchedFields,
    originDomainForm,
    getValues,
    setError
  } = useContext<any>(context);
  const [fieldValidate, setValueValidateField] = useState('');
  const [viewEmail, setViewEmail] = useState(true);
  const url = `/${originDomainForm}/cadastro-email`;
  const navigate = useNavigate();

  useEffect(() => {
    navigate(url, { replace: true });
  }, [navigate]);

  useEffect(() => {
    const subscription = watch((data: any) => {
      setValueValidateField(data.email);
    });
    return () => subscription?.unsubscribe();
  }, [watch]);

  async function callCreateToken(resend?: boolean) {
    await createTokenValidateEmail(fieldValidate);
    if (resend) openAlert();
  }

  const alertBoxRef = useRef<AlertBoxHandles>(null);
  function openAlert() {
    alertBoxRef.current?.openAlert();
  }

  function changeToViewToken() {
    if (socialWithoutEmail) {
      callCreateToken();
      setViewEmail(false);
    } else {
      checkExistEmail();
    }
  }

  async function checkExistEmail() {
    const email = getValues('email');
    try {
      const response = await checkEmailExist(email);
      if (response.exist) {
        setError('email', {
          message: (
            <span>
              endereço de e-mail já vinculado a uma conta.
              <span
                className="redirect-link"
                onClick={() =>
                  navigate(
                    originDomainForm === 'signup'
                      ? '/signin'
                      : originDomainForm === 'sbt/signup'
                        ? '/sbt/signin'
                        : '/videos/signin'
                  )
                }
              >
                fazer login
              </span>
            </span>
          )
        });
      } else {
        callCreateToken();
        setViewEmail(false);
      }
    } catch (error) {
      console.error(error);
      showErrorMessageServer();
    }
  }

  function previousStep() {
    if (previousPath == 0 || previousPath) {
      handleChangeStep(previousPath);
    } else window.location.reload();
  }

  return (
    <>
      {viewEmail ? (
        <div className="flex flex-column">
          <LogoText />
          <h2 className="color-text-default">criar conta</h2>
          <h6 className="mti-8 mbi-8 color-text-default">adicionar e-mail</h6>
          <span className="md mt-4 color-text-default">
            para criar sua Conta SBT precisamos que você forneça um endereço de
            e-mail válido.
          </span>
          <span className="md mt-4 color-text-default">
            enviaremos um código de 6 dígitos para validação do seu e-mail.
          </span>

          <div className="flex flex-column mt-16">
            <FormField
              name="email"
              label="e-mail"
              type="email"
              placeholder="digite seu e-mail"
              register={register('email')}
              error={errors}
              touchedFields={touchedFields}
            />

            <Button
              className="mt-8"
              full
              onClick={changeToViewToken}
              disabled={!!errors.email || !fieldValidate.length}
            >
              <ButtonLabel>enviar código</ButtonLabel>
            </Button>

            <Button
              className="mt-16"
              customColors="btn-white"
              full
              onClick={previousStep}
            >
              <ButtonLabel>voltar</ButtonLabel>
            </Button>
          </div>
        </div>
      ) : (
        <CheckToken
          context={context}
          goBackView={setViewEmail}
          callCreateToken={callCreateToken}
          previousPath={0}
          nextPath={nextPath}
          handleChangeStep={handleChangeStep}
          isEmail
        />
      )}
      <AlertBox ref={alertBoxRef} text="código enviado com sucesso" />
    </>
  );
}
