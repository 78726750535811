import { useContext, useEffect, useState } from 'react';
import Button from '../../components/Button/Button';
import ButtonLabel from '../../components/Button/ButtonLabel';
import FormField from '../../components/FormField/FormField';
import LogoText from '../../components/LogoText/LogoText';
import { useControllerFlowStepsContext } from '../../hooks/ControllerFlowStepsContext';
import { checkEmailExist } from '../../services/queryController';
import { useNavigate } from 'react-router-dom';

export interface ChangeEmailProps {
  setViewChangeEmail: (value: boolean) => void;
  context: any;
}

export default function ChangeEmail({
  context,
  setViewChangeEmail
}: ChangeEmailProps) {
  const {
    register,
    errors,
    touchedFields,
    setValue,
    getValues,
    setError,
    originDomainForm
  } = useContext<any>(context);
  const { showErrorMessageServer } = useControllerFlowStepsContext();

  const [oldEmail, setOldEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setOldEmail(getValues('email'));
  }, []);

  async function confirmChangeEmail() {
    setIsLoading(true);
    if (isLoading) return;
    try {
      const response = await checkEmailExist(getValues('email'));
      if (response.exist) {
        setError('email', {
          message: (
            <span>
              endereço de e-mail já vinculado a uma conta.
              <span
                className="redirect-link"
                onClick={() =>
                  navigate(
                    originDomainForm === 'signup'
                      ? '/signin'
                      : originDomainForm === 'sbt/signup'
                        ? '/sbt/signin'
                        : '/videos/signin'
                  )
                }
              >
                fazer login
              </span>
            </span>
          )
        });
      } else {
        setViewChangeEmail(false);
      }
    } catch {
      showErrorMessageServer();
    } finally {
      setIsLoading(false);
    }
  }

  function handleCancelChangeEmail() {
    setValue('email', oldEmail);
    setViewChangeEmail(false);
  }

  return (
    <>
      <div className="flex flex-column">
        <LogoText />
        <h2>alterar e-mail</h2>

        <div className="flex flex-column mt-16">
          <FormField
            name="email"
            label="e-mail"
            type="email"
            placeholder="digite seu e-mail"
            register={register('email')}
            error={errors}
            touchedFields={touchedFields}
          />

          <Button
            className="mt-8 mbi-0"
            full
            onClick={confirmChangeEmail}
            loading={isLoading}
            disabled={isLoading}
          >
            <ButtonLabel loading={isLoading}>salvar alterações</ButtonLabel>
          </Button>
          <Button
            className="mt-16"
            customColors="btn-white"
            full
            onClick={handleCancelChangeEmail}
          >
            <ButtonLabel>voltar</ButtonLabel>
          </Button>
        </div>
      </div>
    </>
  );
}
