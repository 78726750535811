import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AlertCard from '../../components/AlertCard/AlertCard';
import Button from '../../components/Button/Button';
import ButtonLabel from '../../components/Button/ButtonLabel';
import FormField from '../../components/FormField/FormField';
import LogoText from '../../components/LogoText/LogoText';
import SocialMedia from '../../components/SocialMedia/SocialMedia';
import {
  CODE_ACCOUNT_WITH_EMAIL_NOT_FOUND,
  CODE_CREDENTIAL_WITH_NO_PASS,
  CODE_EMAIL_NOT_SKIP_VALIDATE,
  CODE_NOT_ACCOUNT,
  CTV_SUCCESS_PAGE_PATH,
  PASS_MAX_LENGTH
} from '../../data/Constants';
import { SignInContext } from '../../data/SignIn/SignInContext';
import { useAuthContext } from '../../hooks/AuthContext';
import { useControllerFlowStepsContext } from '../../hooks/ControllerFlowStepsContext';
import { deleteCookie } from '../../services/cookieController';
import { signIn } from '../../services/user';
import {
  setOriginDomainCustom,
  shouldOpenMenuAccount
} from '../../utils/GenericFunctions';
import './SignIn.scss';
import { passwordMask } from '../../utils/Masks';
import { UNEXPECT_ERROR_MESSAGE } from '../../data/ErrorMessages';
import { AccountStatusEnum } from '../../enums/AccountStatusEnum';

const KEY_SESSION = process.env.REACT_APP_KEY_SESSION as string;
const KEY_REFRESH_SESSION = process.env.REACT_APP_KEY_REFRESH_SESSION as string;
const KEY_SESSION_SOCIAL = process.env.REACT_APP_KEY_SESSION_SOCIAL as string;
const KEY_SESSION_PROVIDER = process.env
  .REACT_APP_KEY_SESSION_PROVIDER as string;
const KEY_SESSION_ORIGIN = process.env.REACT_APP_KEY_SESSION_ORIGIN as string;

export interface SignInProps {
  allowSkipStep?: boolean;
  updateData?: boolean;
  previousPath?: number | null;
  nextPath?: number | null;
  handleChangeStep(e: number): void;
  context: any;
  viewSuccess?: any;
}

export default function SignIn({ context }: SignInProps) {
  const {
    setControllerViews,
    getOrigin,
    setStatusAccountUser,
    setAllowSkipValidateEmail,
    setNotCreateAccount,
    ctvCode
  } = useControllerFlowStepsContext();
  const {
    register,
    trigger,
    watch,
    errors,
    touchedFields,
    getValues,
    originDomainForm
  } = useContext(SignInContext);
  const { redirectURL } = useAuthContext();

  const navigate = useNavigate();
  const [fieldsValidate, setValueValidateFields] = useState({
    email: '',
    password: ''
  });
  const [loading, setLoading] = useState(false);
  const [errorRequest, setErrorRequest] = useState('');

  useEffect(() => {
    setOriginDomainCustom();
    deleteCookie('redirect');
  }, []);

  useEffect(() => {
    const subscription = watch((data: any) => {
      const fields = {
        email: data.email,
        password: data.password
      };
      setValueValidateFields(fields);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    setNotCreateAccount(true);
    if (originDomainForm) {
      const url = `/${originDomainForm}`;
      navigate(url, { replace: true });
    }
  }, [originDomainForm]);

  async function checkValidateFields() {
    return await trigger(['email', 'password']);
  }

  useEffect(() => {
    const subscription = watch((data: any) => {
      setValueValidateFields(data);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  async function subtmiLogin() {
    const response = await checkValidateFields();

    if (response) login();
  }

  async function login() {
    const email = getValues('email');
    const password = getValues('password');
    setLoading(true);
    setErrorRequest('');
    try {
      const resp = await signIn(email, password);
      setStatusAccountUser(resp);
      if (resp?.status !== AccountStatusEnum.ACTIVE) {
        const url = `${
          !getOrigin() ? '' : '/' + getOrigin()
        }/reativar-conta?email=${encodeURIComponent(email)}`;
        navigate(url);
      } else {
        if (ctvCode) return navigate(CTV_SUCCESS_PAGE_PATH);
        if (shouldOpenMenuAccount(redirectURL))
          return navigate('/menu-de-conta');
        setControllerViews(resp);
      }
    } catch (error: any) {
      console.log(error);
      if (error?.code === CODE_NOT_ACCOUNT) {
        setErrorRequest('Seu e-mail e/ou senha estão incorretos.');
      } else if (error?.code === CODE_EMAIL_NOT_SKIP_VALIDATE) {
        setAllowSkipValidateEmail(false);
        setControllerViews({ emailValidated: false });
      } else if (error?.code === CODE_ACCOUNT_WITH_EMAIL_NOT_FOUND) {
        setErrorRequest(
          'Não localizamos conta com o endereço de e-mail informado.'
        );
      } else if (error?.code === CODE_CREDENTIAL_WITH_NO_PASS) {
        setErrorRequest('Esta conta não possui senha.');
      } else {
        setErrorRequest(UNEXPECT_ERROR_MESSAGE);
        deleteCookie(KEY_SESSION);
        deleteCookie(KEY_REFRESH_SESSION);
        deleteCookie(KEY_SESSION_SOCIAL);
        deleteCookie(KEY_SESSION_PROVIDER);
        deleteCookie(KEY_SESSION_ORIGIN);
      }
      setLoading(false);
    }
  }

  function redirectToRegister() {
    const url =
      originDomainForm === 'signin'
        ? '/signup/criar-conta'
        : originDomainForm === 'sbt/signin'
          ? '/sbt/signup/criar-conta'
          : '/videos/signup/criar-conta';
    navigate(url);
  }

  function redirectToForgetPassword() {
    navigate(
      getOrigin() == 'videos'
        ? '/videos/recuperar-senha'
        : getOrigin() === 'sbt'
          ? '/sbt/recuperar-senha'
          : '/recuperar-senha'
    );
  }

  function redirectToAccountRecovery() {
    navigate(
      getOrigin() == 'videos'
        ? '/videos/recuperar-conta'
        : getOrigin() === 'sbt'
          ? '/sbt/recuperar-conta'
          : '/recuperar-conta'
    );
  }

  return (
    <div className="flex flex-column">
      <LogoText />
      <h2 className="color-text-default">fazer login</h2>
      <SocialMedia context={context} label="ou entre com" />

      {errorRequest ? <AlertCard text={errorRequest} /> : null}

      <div className="flex flex-column mt-14">
        <div className="form-container">
          <FormField
            name="email"
            label="e-mail"
            type="email"
            placeholder="digite seu e-mail"
            register={register('email')}
            error={errors}
            touchedFields={touchedFields}
          />
          <a
            onClick={redirectToAccountRecovery}
            className="sm-bold link no-decoration link-intern-text ml-3"
            rel="noreferrer"
          >
            Esqueceu seu e-mail?
          </a>
        </div>

        <div className="form-container">
          <FormField
            name="password"
            label="senha"
            type="password"
            maxLength={PASS_MAX_LENGTH}
            placeholder="digite sua senha"
            register={register('password')}
            onInput={passwordMask}
            error={errors}
            touchedFields={touchedFields}
            sufix
            isPassword
          />
          <a
            className="sm-bold link no-decoration link-intern-text ml-3"
            rel="noreferrer"
            onClick={redirectToForgetPassword}
          >
            Esqueceu sua senha?
          </a>
        </div>

        <Button
          id="signin"
          className="mt-34 mb-16"
          full
          onClick={subtmiLogin}
          disabled={
            loading ||
            !fieldsValidate.email.length ||
            !fieldsValidate.password.length ||
            fieldsValidate.password.length < 6 ||
            !!errors.email ||
            !!errors.password
          }
        >
          <ButtonLabel loading={loading}>entrar</ButtonLabel>
        </Button>

        <span className="md mt-4 color-text-default">
          Ainda não tem conta?
          <a
            id="signup-link"
            onClick={redirectToRegister}
            className="strong link-intern-text ml-3 text-purple"
            rel="noreferrer"
          >
            Cadastre-se
          </a>
        </span>
      </div>
    </div>
  );
}
