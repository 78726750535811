import {
  ClipboardEvent,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import './FormField.scss';

interface FormFieldTokenNumberProps {
  setState: React.Dispatch<
    React.SetStateAction<{
      value: string;
      isValid: boolean;
    }>
  >;
  fieldValidate: {
    value: string;
    isValid: boolean;
  };
  resendingCode: boolean;
  errorToken: boolean;
}

export default function FormFieldTokenNumber({
  fieldValidate,
  errorToken,
  setState,
  resendingCode
}: FormFieldTokenNumberProps) {
  const initialValue = {
    number1: '',
    number2: '',
    number3: '',
    number4: '',
    number5: '',
    number6: ''
  };
  const [numbers, setNumbers] = useState(initialValue);
  const refBox1 = useRef<HTMLInputElement>(null);
  const refBox2 = useRef<HTMLInputElement>(null);
  const refBox3 = useRef<HTMLInputElement>(null);
  const refBox4 = useRef<HTMLInputElement>(null);
  const refBox5 = useRef<HTMLInputElement>(null);
  const refBox6 = useRef<HTMLInputElement>(null);

  const checkLength = useCallback(
    (
      e: React.FormEvent<HTMLInputElement | null>,
      inputRef: any,
      previosRef?: RefObject<HTMLInputElement | null>,
      nextRef?: RefObject<HTMLInputElement | null>
    ) => {
      e.currentTarget.maxLength = 1;
      let value = e.currentTarget.value;
      value = value.replace(/\D/g, '');
      if (value?.length > 1) return;
      e.currentTarget.parentElement?.classList.remove(
        'border-filled',
        'border-error'
      );
      e.currentTarget.parentElement?.classList.add(
        value ? 'border-filled' : 'border-error'
      );

      setNumbers((state) => ({ ...state, [inputRef]: value }));
      e.currentTarget.value = value;
      if (e.currentTarget.value.length === 1 && nextRef) {
        nextRef.current?.focus();
      }
    },
    []
  );

  useEffect(() => {
    setErrorToken();
  }, [errorToken]);

  useEffect(() => {
    if (resendingCode) {
      resetFields();
    }
  }, [resendingCode]);

  function resetFields() {
    setNumbers(initialValue);
    const fields: any = document.querySelectorAll('.form-field-blocks__box');
    fields?.forEach((field: any) => {
      field.classList.remove('border-error');
    });
  }

  function setErrorToken() {
    if (fieldValidate.value && fieldValidate.isValid) {
      const fields: any = document.querySelectorAll('.form-field-blocks__box');
      fields?.forEach((field: any) => {
        field.classList.remove('border-filled');
        field.classList.add('border-error');
      });
    }
  }

  function checkInputValids() {
    const numberKeys = Object.keys(numbers).length;
    let countValidInput = 0;
    let token = '';
    for (const field of Object.entries(numbers)) {
      if (field[1]) {
        token = token + field[1];
        countValidInput++;
      }
    }

    setState({ value: token, isValid: countValidInput == numberKeys });
  }

  useEffect(() => {
    checkInputValids();
  }, [numbers]);

  function maxOneNumber(e: React.FormEvent<HTMLInputElement>) {
    e.currentTarget.maxLength = 1;
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, '');

    e.currentTarget.value = value;

    return e;
  }

  const handleKeyDown = (
    e: React.FormEvent<HTMLInputElement> | any,
    previosRef?: RefObject<HTMLInputElement> | any
  ) => {
    const { value } = e.target;
    if (e.keyCode === 8 && value === '') {
      if (previosRef !== null) {
        previosRef.current.focus();
      }
    }
  };

  function handleCodeInputClass(e: HTMLInputElement | null, value: string) {
    if (!e) return;
    e.parentElement?.classList.remove('border-filled', 'border-error');
    e.parentElement?.classList.add(value ? 'border-filled' : 'border-error');
  }

  const handlePaste = (e: ClipboardEvent<HTMLInputElement> | any) => {
    const clipboardValue = e.clipboardData?.getData('Text') || e.target.value;
    if (clipboardValue) {
      const codes = clipboardValue
        .split('')
        .filter((code: string) => code.trim())
        ?.map((el: string) => el.replace(/\D/g, ''));

      if (codes.length === 6) {
        console.log(codes);
        setNumbers({
          number1: codes[0],
          number2: codes[1],
          number3: codes[2],
          number4: codes[3],
          number5: codes[4],
          number6: codes[5]
        });

        handleCodeInputClass(refBox1.current, codes[0]);
        handleCodeInputClass(refBox2.current, codes[1]);
        handleCodeInputClass(refBox3.current, codes[2]);
        handleCodeInputClass(refBox4.current, codes[3]);
        handleCodeInputClass(refBox5.current, codes[4]);
        handleCodeInputClass(refBox6.current, codes[5]);

        if (refBox6.current) refBox6.current.focus();
      }
    }
  };

  return (
    <>
      <div className="form-field-blocks">
        <div className="form-field-blocks__box">
          <input
            id="single-factor-code-text-field"
            autoComplete="one-time-code"
            ref={refBox1}
            type="tel"
            value={numbers.number1}
            onKeyUp={maxOneNumber}
            onInput={(e) => checkLength(e, 'number1', undefined, refBox2)}
            onKeyDown={(e) => handleKeyDown(e, null)}
            onChange={(e: any) => {
              if (e.target.value.length > 1) handlePaste(e);
            }}
            onPaste={handlePaste}
            inputMode="numeric"
          />
        </div>
        <div className="form-field-blocks__box">
          <input
            ref={refBox2}
            type="tel"
            value={numbers.number2}
            onKeyUp={maxOneNumber}
            onInput={(e) => checkLength(e, 'number2', refBox1, refBox3)}
            onKeyDown={(e) => handleKeyDown(e, refBox1)}
            inputMode="numeric"
          />
        </div>
        <div className="form-field-blocks__box">
          <input
            ref={refBox3}
            type="tel"
            value={numbers.number3}
            onKeyUp={maxOneNumber}
            onInput={(e) => checkLength(e, 'number3', refBox2, refBox4)}
            onKeyDown={(e) => handleKeyDown(e, refBox2)}
            inputMode="numeric"
          />
        </div>

        <div className="form-field-blocks__box">
          <input
            ref={refBox4}
            type="tel"
            value={numbers.number4}
            onKeyUp={maxOneNumber}
            onInput={(e) => checkLength(e, 'number4', refBox3, refBox5)}
            onKeyDown={(e) => handleKeyDown(e, refBox3)}
            inputMode="numeric"
          />
        </div>
        <div className="form-field-blocks__box">
          <input
            ref={refBox5}
            type="tel"
            value={numbers.number5}
            onKeyUp={maxOneNumber}
            onInput={(e) => checkLength(e, 'number5', refBox4, refBox6)}
            onKeyDown={(e) => handleKeyDown(e, refBox4)}
            inputMode="numeric"
          />
        </div>
        <div className="form-field-blocks__box">
          <input
            ref={refBox6}
            type="tel"
            value={numbers.number6}
            onKeyUp={maxOneNumber}
            onInput={(e) => checkLength(e, 'number6', refBox5)}
            onKeyDown={(e) => handleKeyDown(e, refBox5)}
            inputMode="numeric"
          />
        </div>
      </div>
      {errorToken ? (
        <span className="sm error-token">código incorreto ou expirado.</span>
      ) : null}
    </>
  );
}

// ex: Implementation
{
  /*
      <FormFieldTokenNumber />
  */
}
