import { createContext, useContext } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CODE_EMAIL_NOT_SKIP_VALIDATE,
  CODE_NOT_ACCOUNT_CREATED,
  CODE_NOT_EMAIL_LOGIN_SOCIAL,
  CTV_SUCCESS_PAGE_PATH,
  PROVIDER_SOCIAL
} from '../data/Constants';
import { sendSocialData } from '../services/socialNetWorkController';
import {
  logoutSSO,
  mergeAccount,
  validateUserIdentity
} from '../services/user';
import { useControllerFlowStepsContext } from './ControllerFlowStepsContext';
import { useAuthContext } from './AuthContext';
import { getGoogleUserInfo } from '../services/google';
import { SentryContextsEnum, sendSentryMessage } from '../utils/Sentry';
import { ErrorMessages, UNEXPECT_ERROR_MESSAGE } from '../data/ErrorMessages';
import {
  IResponseAuthorizationApple,
  IResponseTokenSocial
} from '../interfaces/ISocial';
import { AccountStatusEnum } from '../enums/AccountStatusEnum';
import {
  extractCtvCodeFromCookie,
  shouldOpenMenuAccount
} from '../utils/GenericFunctions';

export enum SocialActionEnum {
  DEFAULT = 'default',
  CONNECT_ACCOUNT = 'connectAccount',
  VALIDATE_IDENTITY = 'validateIdentity'
}

interface ObservableChangeStatus {
  observing: boolean;
  success: 'OK' | 'REJECT' | 'LOADING' | '';
  provider:
    | PROVIDER_SOCIAL.FACEBOOK
    | PROVIDER_SOCIAL.GOOGLE
    | PROVIDER_SOCIAL.APPLE
    | '';
  code?: string;
}

export interface ControllerSocialMediaContextData {
  loginFace(connectAccount?: SocialActionEnum): void;
  clientGoogle: any;
  loginGoogle(): void;
  loginApple(
    connectAccount?: SocialActionEnum,
    redirectOption?: boolean
  ): Promise<void>;
  setClientGoogle: React.Dispatch<any>;
  googleHandleCredentialResponse(
    response: any,
    connect?: SocialActionEnum
  ): Promise<void>;
  sendToken(token: string, provider: string, userName?: string): void;
  observableChangeStatus: ObservableChangeStatus;
  setObservableChangeStatus: React.Dispatch<
    React.SetStateAction<ObservableChangeStatus>
  >;
}

export const ControllerSocialMediaContext = createContext(
  {} as ControllerSocialMediaContextData
);

const SocialMediaProvider = ({ children }: any) => {
  const {
    setSocialData,
    setControllerViews,
    setSocialWithoutEmail,
    setTokenSocial,
    setProviderSocial,
    setLoadingFullScreen,
    setAllowSkipValidateEmail,
    getOrigin,
    setStatusAccountUser,
    setNotCreateAccount,
    setAlertBox,
    showErrorMessageServer,
    ctvCode,
    setCtvCode
  } = useControllerFlowStepsContext();
  const { user, checkAuth, redirectURL, redirectToSite } = useAuthContext();
  const [clientGoogle, setClientGoogle] = useState<any>();
  const [observableChangeStatus, setObservableChangeStatus] =
    useState<ObservableChangeStatus>({
      observing: false,
      success: '',
      provider: ''
    });

  const navigate = useNavigate();

  function sendSocialSentryMessage(e: any, provider: PROVIDER_SOCIAL) {
    const sentryMessage = ErrorMessages.SOCIAL;
    sentryMessage.extras = { provider };
    sendSentryMessage(sentryMessage, e);
  }

  //#region facebook
  function loginFace(connectAccount?: SocialActionEnum) {
    setLoadingFullScreen(true);
    window.FB.login(
      function (response: any) {
        statusChangeCallbackFacebook(response, connectAccount);
      },
      { scope: 'email', return_scopes: true }
    );
  }

  function statusChangeCallbackFacebook(
    response: any,
    connectAccount?: SocialActionEnum
  ) {
    try {
      window.FB.api('/me', (facebook: any) => {
        if (response.status === 'connected') {
          if (connectAccount === SocialActionEnum.CONNECT_ACCOUNT) {
            connectToAccount(
              response.authResponse.accessToken,
              PROVIDER_SOCIAL.FACEBOOK
            );
          } else if (connectAccount === SocialActionEnum.VALIDATE_IDENTITY) {
            validateIdentity(
              response.authResponse.accessToken,
              PROVIDER_SOCIAL.FACEBOOK
            );
          } else {
            sendToken(
              response.authResponse.accessToken,
              PROVIDER_SOCIAL.FACEBOOK,
              facebook?.name
            );
          }
        } else {
          // The person is not logged into your app or we are unable to tell.
          setLoadingFullScreen(false);
        }
      });
    } catch (e) {
      console.error(e);
      setLoadingFullScreen(false);
      sendSocialSentryMessage(e, PROVIDER_SOCIAL.FACEBOOK);
    }
  }
  //#endregion facebook

  //#region google
  interface IGoogleResponse {
    accessToken: string;
    tokenId: string;
  }

  async function googleHandleCredentialResponse(
    response: IGoogleResponse,
    connectAccount?: SocialActionEnum
  ) {
    setLoadingFullScreen(true);
    let userName;

    try {
      const googleUserInfo = await getGoogleUserInfo(response.accessToken);
      if (googleUserInfo?.name) userName = googleUserInfo?.name;
    } catch (e) {
      console.error(e);
      sendSocialSentryMessage(e, PROVIDER_SOCIAL.GOOGLE);
    }

    if (connectAccount === SocialActionEnum.CONNECT_ACCOUNT) {
      connectToAccount(response.tokenId, PROVIDER_SOCIAL.GOOGLE);
    } else if (connectAccount === SocialActionEnum.VALIDATE_IDENTITY) {
      validateIdentity(response.tokenId, PROVIDER_SOCIAL.GOOGLE);
    } else {
      sendToken(response.tokenId, PROVIDER_SOCIAL.GOOGLE, userName);
    }
  }

  function loginGoogle() {
    clientGoogle.requestAccessToken();
  }
  //#endregion google

  //#region apple
  async function loginApple(
    connectAccount?: SocialActionEnum,
    redirectOption = false
  ) {
    try {
      setLoadingFullScreen(true);
      const data: IResponseAuthorizationApple =
        await window.AppleID.auth.signIn();

      if (connectAccount === SocialActionEnum.CONNECT_ACCOUNT) {
        connectToAccount(data.authorization.id_token, PROVIDER_SOCIAL.APPLE);
      } else if (connectAccount === SocialActionEnum.VALIDATE_IDENTITY) {
        validateIdentity(data.authorization.id_token, PROVIDER_SOCIAL.APPLE);
      } else {
        sendToken(
          data.authorization.id_token,
          PROVIDER_SOCIAL.APPLE,
          data?.user &&
            data?.user?.name &&
            data?.user?.name?.firstName &&
            data?.user?.name?.lastName
            ? data?.user?.name?.firstName.trim() +
                ' ' +
                data?.user?.name?.lastName.trim()
            : undefined
        );
      }
    } catch (error: any) {
      sendSocialSentryMessage(error, PROVIDER_SOCIAL.APPLE);
      setLoadingFullScreen(false);
      console.error(error);
      if (error?.error != 'popup_closed_by_user' && !redirectOption) {
        rejectConnectAccount();
      }
    }
  }
  //#endregion apple

  async function connectToAccount(token: string, provider: string) {
    try {
      connectAccounMerge(token, provider);
    } catch {
      rejectConnectAccount();
    }
  }

  async function connectAccounMerge(token: string, provider: string) {
    try {
      await mergeAccount(
        {
          providerToken: token,
          provider
        },
        SentryContextsEnum.ACCOUNT_MENU_MERGE
      );
      await sendSocialData(token, provider, user.email, true);
      setAlertBox({
        message: `sua conta d${provider === 'APPLE' ? 'a' : 'o'} ${
          provider.charAt(0).toUpperCase() + provider.slice(1).toLowerCase()
        } foi conectada`,
        error: false
      });
      setObservableChangeStatus((state) => ({ ...state, success: 'OK' }));
      closeSession();
    } catch (error: any) {
      console.log(error);
      if (error?.statusCode === 409)
        return rejectConnectAccountExisting(provider);
      else showErrorMessageServer();
    } finally {
      setLoadingFullScreen(false);
    }
  }

  function rejectConnectAccountExisting(provider: string) {
    setAlertBox({
      message: `não é possível associar este perfil de ${
        provider.charAt(0).toUpperCase() + provider.slice(1).toLowerCase()
      } pois ele já se encontra associado a outra conta de usuário`,
      error: true
    });
    setObservableChangeStatus((state) => ({ ...state, success: 'REJECT' }));
    setLoadingFullScreen(false);
  }

  function rejectConnectAccount() {
    setAlertBox({
      message:
        'Não foi possível conectar sua conta. Tente novamente mais tarde.',
      error: true
    });
    setObservableChangeStatus((state) => ({ ...state, success: 'REJECT' }));
    setLoadingFullScreen(false);
  }

  async function closeSession() {
    await logoutSSO();
  }

  async function validateIdentity(token: string, provider: string) {
    try {
      const response = await validateUserIdentity({
        email: checkAuth()?.user?.email,
        providerToken: token,
        provider
      });
      if (response?.indentityValidated) {
        setObservableChangeStatus((state) => ({
          ...state,
          observing: true,
          success: 'OK',
          code: response?.code
        }));
        setLoadingFullScreen(false);
        return;
      }

      setLoadingFullScreen(false);
      setObservableChangeStatus((state) => ({
        ...state,
        observing: true,
        success: 'REJECT'
      }));
      setAlertBox({
        message: 'não foi possível autenticar a conta',
        error: true
      });
    } catch {
      rejectConnectAccount();
    }
  }

  async function sendToken(token: string, provider: string, userName?: string) {
    setTokenSocial(token);
    setProviderSocial(provider);
    try {
      const resp = await sendSocialData(token, provider);
      if (userName) resp.userName = userName;
      setSocialData(resp);
      await checkData(resp);
    } catch (error: any) {
      console.log(error);
      // If 404 in login should try to merge account
      if (error?.statusCode === 404) {
        if (userName)
          setSocialData({
            userName,
            email: ''
          });

        if (error?.code === CODE_NOT_EMAIL_LOGIN_SOCIAL) {
          // send to create account
          setSocialWithoutEmail(true);
          setNotCreateAccount(false);
          const url = `/${getOriginNavigate()}`;
          navigate(url, {
            state: { createAccountFromSocialWithoutEmail: true }
          });
        } else {
          await mergeSocialAccount(token, provider);
        }
      }
      console.log(error);
      setLoadingFullScreen(false);
    }
  }

  async function mergeSocialAccount(token: string, provider: string) {
    try {
      await mergeAccount({
        provider,
        providerToken: token
      });
      setControllerViews({ mergeAccount: true });
    } catch (error: any) {
      if (error?.statusCode === 404) {
        await redirectToCreateAccount();
      } else {
        console.log(error);
        setLoadingFullScreen(false);
      }
    }
  }

  async function redirectToCreateAccount() {
    setNotCreateAccount(false);
    const url = `/${getOriginNavigate()}`;
    navigate(url, {
      state: { createAccountFromSocial: true }
    });
  }

  async function checkData(response: IResponseTokenSocial) {
    try {
      setStatusAccountUser(response);
      if (response.status === AccountStatusEnum.INACTIVE) {
        const url = `${
          !getOrigin() ? '' : '/' + getOrigin()
        }/reativar-conta?email=${encodeURIComponent(response?.email)}`;
        navigate(url);
      } else {
        if (ctvCode || extractCtvCodeFromCookie(setCtvCode))
          return navigate(CTV_SUCCESS_PAGE_PATH);
        if (shouldOpenMenuAccount(redirectURL))
          return navigate('/menu-de-conta');
        return redirectToSite();
      }
    } catch (error: any) {
      if (error?.code === CODE_NOT_ACCOUNT_CREATED) {
        // send to create account
        setNotCreateAccount(false);
        const url = `/${getOriginNavigate()}`;
        navigate(url, {
          state: { createAccountFromSocial: true }
        });
      } else if (error?.code === CODE_EMAIL_NOT_SKIP_VALIDATE) {
        setAllowSkipValidateEmail(false);
        setControllerViews({ emailValidated: false });
      } else {
        setAlertBox({
          message: UNEXPECT_ERROR_MESSAGE,
          error: true
        });
      }
      console.log(error);
      setLoadingFullScreen(false);
    }
  }

  function getOriginNavigate() {
    const regexOrigin = /videos/g;
    const regexAppOrigin = /sbt/g;
    if (regexOrigin.test(window.location.pathname)) {
      return 'videos/signup';
    } else if (regexAppOrigin.test(window.location.pathname)) {
      return 'sbt/signup';
    } else {
      return 'signup';
    }
  }

  return (
    <ControllerSocialMediaContext.Provider
      value={{
        loginFace,
        loginApple,
        loginGoogle,
        clientGoogle,
        setClientGoogle,
        googleHandleCredentialResponse,
        observableChangeStatus,
        setObservableChangeStatus,
        sendToken
      }}
    >
      {children}
    </ControllerSocialMediaContext.Provider>
  );
};

export const useControllerSocialMediaContext = () =>
  useContext(ControllerSocialMediaContext);

export default SocialMediaProvider;
