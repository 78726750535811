import './ButtonLabel.scss';
import Loading from '../Loading/Loading';
import { ReactNode } from 'react';
interface ButtonLabelProps {
  loading?: boolean;
  loaderColor?: string;
  children: ReactNode;
}

export default function ButtonLabel(props: ButtonLabelProps) {
  const { loading, loaderColor = 'white' } = props;
  if (loading) {
    return <Loading color={loaderColor} />;
  }
  return <span>{props.children}</span>;
}
