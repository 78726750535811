import axios from 'axios';
import { ICepResponse, ICityResponse } from '../interfaces/QueryController';

const VIACEP_BASE_URL = 'https://viacep.com.br';
const BRASIL_API_BASE_URL = 'https://brasilapi.com.br';

export async function getCep(cep: string): Promise<ICepResponse> {
  const response = await axios.get(`${VIACEP_BASE_URL}/ws/${cep}/json/`);
  const result = response?.data;

  return {
    bairro: result?.bairro,
    cep: result?.cep,
    complemento: result?.complemento,
    ddd: result?.ddd,
    gia: result?.gia,
    ibge: result?.ibge,
    localidade: result?.localidade,
    logradouro: result?.logradouro,
    siafi: result?.siafi,
    uf: result?.uf
  };
}

interface IBrasilAPICity {
  id: number;
  nome: string;
  estado: string;
}

export async function getCityUs(city: string): Promise<ICityResponse[]> {
  const response = await axios.get(
    `${BRASIL_API_BASE_URL}/api/cptec/v1/cidade/${city}`
  );

  const result = response?.data as IBrasilAPICity[];

  return result?.map((city) => ({
    city: city?.nome,
    sbtAddressInformationId: city?.id?.toString(),
    state: city?.estado,
    uf: city?.estado
  }));
}
