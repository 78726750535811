import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import ButtonLabel from '../../../components/Button/ButtonLabel';
import LogoText from '../../../components/LogoText/LogoText';
import { useControllerFlowStepsContext } from '../../../hooks/ControllerFlowStepsContext';
import './AccountRecoveryAccess.scss';
import Icon from '../../../components/Icon/Icon';
import { getSocialsLoginByIdentifier } from '../../../services/queryController';
import { useAccountRecoveryContext } from '../../../data/AccountRecovery/AccountRecoveryContext';

export interface AccessesRecoveryProps {
  allowSkipStep?: boolean;
  updateData?: boolean;
  previousPath?: number | null;
  nextPath?: number | null;
  handleChangeStep(e: number): void;
  context: any;
}

export default function AccessesRecovery() {
  const {
    getOrigin,
    setLoadingFullScreen,
    showErrorMessageServer,
    showCooldownErrorMessage
  } = useControllerFlowStepsContext();
  const { getValues } = useAccountRecoveryContext();
  const [accountInfos, setAccountInfos] = useState({} as any);
  const [existsSocialLogin, setExistsSocialLogin] = useState(false);
  const url = `${
    !getOrigin()
      ? '/recuperar-conta/informacoes-login'
      : '/' + getOrigin() + '/recuperar-conta/informacoes-login'
  }`;
  const navigate = useNavigate();

  useEffect(() => {
    navigate(url);
  }, [navigate]);

  useEffect(() => {
    setLoadingFullScreen(true);
    getAccountInfos();
  }, []);

  useEffect(() => {
    checkSocialLogin();
  }, [accountInfos]);

  async function getAccountInfos() {
    let phone = getValues('cellPhoneNumber');
    const phoneCode = getValues('code') || '';
    phone = phone?.replace(/\D/g, '');
    try {
      const responsePendingData = await getSocialsLoginByIdentifier(
        phone,
        phoneCode,
        'cellPhone'
      );
      setAccountInfos(responsePendingData);
      setLoadingFullScreen(false);
    } catch (error: any) {
      if (error?.statusCode === 429) showCooldownErrorMessage();
      else showErrorMessageServer();
    }
  }

  function checkSocialLogin() {
    const listLogin = Object.entries(accountInfos)
      .map((key) => {
        return accountInfos[key[0]] == true ? key[0] : false;
      })
      .filter((item) => !!item);
    setExistsSocialLogin(!!listLogin.length);
  }

  function showIconFace() {
    return accountInfos.loginFace ? (
      <div>
        <Icon name="logoFace" />
        <span className="md-bold-700">Facebook</span>
      </div>
    ) : null;
  }

  function showIconGoogle() {
    return accountInfos.loginGoogle ? (
      <div>
        <Icon name="logoGoogle" />
        <span className="md-bold-700">Google</span>
      </div>
    ) : null;
  }

  function showIconApple() {
    return accountInfos.loginApple ? (
      <div>
        <Icon name="logoApple" />
        <span className="md-bold-700">Apple</span>
      </div>
    ) : null;
  }

  function redirect() {
    navigate(
      getOrigin() == 'videos' ? '/videos' : getOrigin() === 'sbt' ? '/sbt' : '/'
    );
  }

  return (
    <>
      <div className="flex flex-column">
        <LogoText />
        <h2 className="color-text-default">seu login da Conta SBT</h2>
        <span className="md mt-16 color-text-default">
          <span className="strong">E-mail cadastrado:</span>
          <br />
          {accountInfos.email}
        </span>

        <div className="flex flex-column mt-22">
          {existsSocialLogin ? (
            <>
              <span className="lg strong mb-0">contas conectadas</span>
              <div className="flex social-medias-icons mb-32">
                {showIconFace()}
                {showIconGoogle()}
                {showIconApple()}
              </div>
            </>
          ) : null}

          <Button className="mbi-0" full onClick={redirect}>
            <ButtonLabel>fazer login</ButtonLabel>
          </Button>
        </div>
      </div>
    </>
  );
}
