import './Card.scss';

interface CardProps {
  id?: string;
  width?: string;
  maxWidth?: string;
  height?: string;
  minHeight?: string;
  full?: boolean;
  center?: boolean;
  sticky?: boolean;
  stickyTop?: number;
  disabled?: boolean;
  paddingVertical?: string;
  paddingHorizontal?: string;
  boxShadow?: string;
  children: React.ReactNode;
}

export default function Card({
  id,
  full,
  disabled,
  center,
  width = 'auto',
  maxWidth = 'auto',
  height = 'auto',
  minHeight = 'auto',
  paddingVertical = '32',
  paddingHorizontal = '32',
  boxShadow = '0px 0px 25px rgba(0, 0, 0, 0.1)',
  sticky,
  stickyTop = 16,
  ...props
}: CardProps) {
  const custonStyle = {
    width: width,
    maxWidth: maxWidth,
    height: height,
    minHeight: minHeight,
    padding: `${paddingVertical}px ${paddingHorizontal}px`,
    margin: center ? 'auto' : '',
    boxShadow: boxShadow,
    top: `${sticky ? stickyTop : 0}px`
  };

  return (
    <div
      id={id}
      style={custonStyle}
      className={
        'card ' +
        (full ? 'card-full' : '') +
        (disabled ? ' card-disabled' : '' + (sticky ? ' card-sticky' : ''))
      }
    >
      {props.children}
    </div>
  );
}
