import Button from '../../../components/Button/Button';
import Card from '../../../components/Card/Card';
import Container from '../../../components/Container/Container';
import ButtonLabel from '../../../components/Button/ButtonLabel';
import './ManageActiveSessions.scss';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../hooks/AuthContext';
import { useControllerFlowStepsContext } from '../../../hooks/ControllerFlowStepsContext';
import Modal, { ModalHandles } from '../../../components/Modal/Modal';
import ModalTitle from '../../../components/Modal/ModalTitle';
import ModalDescription from '../../../components/Modal/ModalDescription';
import Icon from '../../../components/Icon/Icon';
import {
  getListAllSessions,
  removeSessions
} from '../../../services/user/session';
import { IUserSession } from '../../../interfaces/user/Session';
import { formatDateToView } from '../../../utils/date';
import {
  convertUserAgentToBrowser,
  convertUserAgentToOS
} from '../../../utils/device';

export default function ManageActiveSessions() {
  const { checkAuth } = useAuthContext();
  const { loadingFullScreen, setAlertBox, setLoadingFullScreen } =
    useControllerFlowStepsContext();

  const navigate = useNavigate();
  const [currentSession, setCurrentSession] = useState<
    IUserSession | undefined
  >(undefined);
  const [sessions, setSessions] = useState<IUserSession[]>([]);

  const modalRef = useRef<ModalHandles>(null);
  function openModal() {
    modalRef.current?.openModal();
  }

  function closeModal() {
    modalRef.current?.closeModal();
  }

  useEffect(() => {
    listAllSessions();
  }, []);

  async function listAllSessions() {
    setLoadingFullScreen(true);
    const check = checkAuth();
    if (check?.user) {
      try {
        const response = await getListAllSessions();
        setCurrentSession(
          response?.sessions?.find(
            (session) => session?.id === check?.sessionId
          )
        );
        setSessions(response?.sessions);
      } catch {
        setAlertBox({
          message:
            'Não foi possível carregar as informações. Tente novamente mais tarde.',
          error: true
        });
      } finally {
        setLoadingFullScreen(false);
      }
    }
  }

  async function removeSession(sessionId: number) {
    try {
      setLoadingFullScreen(true);
      await removeSessions([sessionId]);
      setSessions(sessions.filter((session) => session.id !== sessionId));
      setLoadingFullScreen(false);
      setAlertBox({
        message: 'O dispositivo foi desconectado',
        error: false
      });
    } catch {
      setLoadingFullScreen(false);
      setAlertBox({
        message:
          'Não foi possível desconectar o dispositivo. Tente novamente mais tarde.',
        error: true
      });
    }
  }

  async function removeAllSession() {
    try {
      if (sessions.length === 1)
        setAlertBox({
          message: 'Não é possível desconectar a própria sessão.',
          error: true
        });

      const sessionsIds = sessions
        .filter((session) => session.id !== currentSession?.id)
        .map((session) => session.id);

      await removeSessions(sessionsIds);
      if (currentSession) setSessions([currentSession]);
      else setSessions([]);

      closeModal();
    } catch {
      setLoadingFullScreen(false);
      setAlertBox({
        message:
          'Não foi possível desconectar os dispositivos. Tente novamente mais tarde.',
        error: true
      });
    }
  }

  function redirect() {
    navigate('/menu-de-conta/');
  }

  return (
    <Container>
      <div className="menu-account height-full">
        <div className="menu-account__left">
          <Card paddingHorizontal="0" paddingVertical="0" sticky>
            <div className="update-user-data-menu">
              <div className="update-user-data-menu__link link-selected not-link">
                Dispositivos conectados
              </div>
              <div className="pl-32 pr-32 width-full flex flex-column border-box">
                <Button
                  loading={loadingFullScreen}
                  className="mt-24 mbi-0"
                  onClick={openModal}
                  disabled={loadingFullScreen || sessions?.length === 0}
                >
                  <ButtonLabel>desconectar todos</ButtonLabel>
                </Button>
                <Button
                  loading={loadingFullScreen}
                  onClick={redirect}
                  className="mt-16 mb-24"
                  customColors="btn-white"
                >
                  <ButtonLabel>voltar</ButtonLabel>
                </Button>
              </div>
            </div>
          </Card>
        </div>
        <div className="menu-account__right">
          {sessions.map((item, index: number) => {
            return (
              <Card key={index}>
                <div className="browse-icon">
                  <Icon name={convertUserAgentToBrowser(item?.userAgent)} />
                </div>
                <div className="info">
                  <b className="browser">
                    {item.userAgent
                      ? convertUserAgentToBrowser(item.userAgent)
                      : 'Não informado'}
                    {' - '}
                    {item.userAgent
                      ? convertUserAgentToOS(item?.userAgent)
                      : 'Não informado'}
                  </b>
                  <br />
                  última sessão ativa:{' '}
                  {item.lastUseAt
                    ? formatDateToView(item.lastUseAt)
                    : 'Não informado'}{' '}
                  <br />
                  IP: {item.ip ? item.ip : 'Não informado'} <br />
                </div>

                {item.id === currentSession?.id ? (
                  <div className="session-action">
                    <b>
                      <span className="current-session">Sessão atual</span>
                    </b>
                  </div>
                ) : (
                  <div
                    onClick={() => removeSession(item.id)}
                    className="remove-one-session-btn session-action"
                  >
                    <Icon
                      name="trashButton"
                      className="ml-16 menu-account-btn-icon"
                    />
                  </div>
                )}
              </Card>
            );
          })}
        </div>
      </div>

      <Modal ref={modalRef}>
        <div className="caution">
          <Icon name="caution"></Icon>
        </div>
        <ModalTitle>desconectar todos os dispositivos</ModalTitle>
        <ModalDescription>
          Todas as sessões ativas da sua Conta SBT serão encerradas. <br />{' '}
          Deseja continuar?
        </ModalDescription>
        <div className="modal-actions">
          <Button
            id="remove-all-sessions"
            full
            className="mbi-0"
            onClick={removeAllSession}
          >
            <ButtonLabel>desconectar todos</ButtonLabel>
          </Button>

          <Button
            full
            customColors="btn-white"
            className="mbi-0"
            onClick={closeModal}
          >
            <ButtonLabel>cancelar</ButtonLabel>
          </Button>
        </div>
      </Modal>
    </Container>
  );
}
